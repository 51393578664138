
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { connection } from '../../environments/connection.properties';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable()
export class HttpCoreService {

	urlToCall = '';
	

	constructor(
		public httpClient: HttpClient,
		private spinnerService: Ng4LoadingSpinnerService
    ){}

	getHttpUrl(url, reqParam, methodType){
		/* console.log('Inside the getHttpUrl start...');
		console.log(url);
		console.log(reqParam);
		console.log(methodType);
		console.log('Inside the getHttpUrl end...'); */
    
    	this.urlToCall = '';
    
    	if(connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true'){
			this.urlToCall = location.protocol + "//" + location.hostname + ":" + 
				connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
		} else {
			this.urlToCall = connection.API_FULL_URL;
		}
    
    	if (environment.envName === 'json'){
      		return this.httpClient.get(url).pipe(
        	map((response: Response) => {
          		return response.json();
        	}),
        	catchError((e: any) => observableThrowError(this.errorHandler(e))),);
    	} else if (methodType === 'json'){
    	
    		return this.httpClient.get(url).pipe(
        	map((response: Response) => {
          		return response;
        	}),
        	catchError((e: any) => observableThrowError(this.errorHandler(e))),);
    	
    	} else {
    		this.urlToCall += url;
    	
      		if(methodType === 'get'){
        		return this.httpClient.get(this.urlToCall).pipe(
          		map((response: Response) => {
            		return response;
          		}),
          		catchError((e: any) => observableThrowError(this.errorHandler(e))),);
      		} else { //POST METHOD
				return this.httpClient.post(this.urlToCall, JSON.stringify(reqParam)).pipe(
          		map((response: Response) => {
            		return response;
          		}
          		),catchError((e: any) => observableThrowError(this.errorHandler(e))),);
			}
		}
	}

	errorHandler(error: any): void {
		console.log(error);
		this.spinnerService.hide();
	}
	
}