import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MSG_CONST } from '../layout/message-creation/constants/message-creation.constant';

@Injectable()
export class TreeHelperService {

  selectedIds = [];
  slctAllModulesHeader = false;
  constructor() { }

  addVisibleFlag(data) {
    data.forEach(node => {
      node.visible = true;
      if (node.children && node.children.length) {
        this.addVisibleFlag(node.children);
      }
    });
  }

  addVisibleFlagForNode(data, filterString) {
    if(filterString.length > 0) {
      let flag = false;
      data.forEach(node => {
        flag = false;
        
        if((node.levelName == MSG_CONST.Region || node.levelName == "Canada") && node.name.toLowerCase().includes(filterString.toLowerCase())) {
          node.visible = true;
          flag = true;
          node.children ? this.addVisibleFlag(node.children) : '';
        }
        if((node.levelName == "Coop" || node.levelName == "Province") && node.name.toLowerCase().includes(filterString.toLowerCase())) {
          node.visible = true;
          flag = true;
          node.children ? this.addVisibleFlag(node.children) : '';
        }
        
        if (!flag && (node.levelName != "Coop" || node.levelName != "Province") && node.children && node.children.length) {
          node.visible = true;
          this.addVisibleFlagForNode(node.children, filterString);
        }
      });
    }
  }

  disableTillLastChild(node) {
    if (node.selected) {
      this.disableNodes(node, true);
    } else {
      if (node.children) {
        node.children.forEach((child) => this.disableTillLastChild(child));
      }
    }
  }

  disableTillLastChildForOOP(node) {
    node.forEach((child) => {
      if (child.selected) {
        this.disableNodes(child, true);
      } else {
        if (child.children) {
          child.children.forEach((child2) => this.disableTillLastChild(child2));
        }
      }
    });
  }

  public disableNodes(node, boolean) {
    node.disabled = boolean;
    // making the node unselected again.
    node.selected = false;
    if (node.children) {
      node.children.forEach((child) => this.disableNodes(child, boolean));
    }
  }

  enableUnselectedNodes(tree, removedItems) {
    tree.forEach(node => {
      if (_.findIndex(removedItems, { 'child': node.child }) !== -1) {
        node.disabled = false;
        node.selected = false;
          
        this.enableParents(node, node.children);
      }
      if (node.children && node.children.length) {
        this.enableUnselectedNodes(node.children, removedItems);
      }
    });
  }

  disableUnselectedNodes(tree, removedItems) {
    tree.forEach(node => {
      if (_.findIndex(removedItems, { 'child': node.child }) !== -1) {
        node.disabled = true;
        node.selected = true;
          
        this.disableParents(node, node.children);
      }
      if (node.children && node.children.length) {
        this.disableUnselectedNodes(node.children, removedItems);
      }
    });
  }

  enableParentNode(node, item) {
    if (_.findIndex(item, { 'parentNodeId': node.child }) !== -1) {
      this.enableParents(node, item);
    }else if (_.findIndex(item, { 'child': node.child }) !== -1) {
      this.enableParents(node, item);
    }
     else {
      if (node.children) {
        node.children.forEach((child) => this.enableParentNode(child, item));
      }
    }
  }

  enableParentNodeForOOP(node_arr, item) {
    node_arr.forEach( (node) => {
      if (node.children) {
        node.children.forEach((child) => this.enableParentNode(child, item));
      } else {
        this.enableParents(node, item);
      }
    });
  }

  enableParents(node, item) {
    if (node.levelName !== MSG_CONST.Store && node.levelName !== MSG_CONST.Restaurant) {
		if (_.findIndex(item, { 'parent': node.child }) !== -1){
      node.disabled = false;
      node.selected = false;
		}
		else if (node.levelName == MSG_CONST.Market || node.levelName == MSG_CONST.Canada)
		{
	    node.disabled = false;
      node.selected = false;
		} else if (node.levelName == MSG_CONST.Region) {
      node.children.forEach((nd) => {
        if(_.findIndex(item, { 'parent' : nd.child}) !== -1) {
          node.disabled = false;
          node.selected = false;
        }
      });
    }
      if (node.children) {
        node.children.forEach((child) => this.enableParents(child, item));
      }
    } else if (_.findIndex(item, { 'child': node.child }) !== -1) {
      node.disabled = false;
      node.selected = false;
    }
  }

  disableParents(node, item) {
    if (node.levelName !== MSG_CONST.Store && node.levelName !== MSG_CONST.Restaurant) {
		if (_.findIndex(item, { 'parent': node.child }) !== -1){
      node.disabled = true;
      node.selected = true;
		}
		else if (node.levelName == MSG_CONST.Region || node.levelName == MSG_CONST.Market)
		{
	  node.disabled = true;
      node.selected = true;
		}
      if (node.children) {
        node.children.forEach((child) => this.disableParents(child, item));
      }
    } else if (_.findIndex(item, { 'child': node.child }) !== -1) {
      node.disabled = true;
      node.selected = true;
    }
  }

  filterTree(treeNode, searchString, column) {
    let temp = [];
    // Map < Integer, Boolean > parentShowMap = new HashMap<Integer, Boolean>();
    let parentShowMap = new Map();

    if (this.matchSearchCriteria(treeNode, searchString, column)) {
      treeNode.visibleDefault = true;
      // parentShowMap.put(vo.getChild(), true);
      parentShowMap.set(treeNode.child, true);
      treeNode.expanded = true;
    }
    // ListIterator < TreeGeneratorResponseVO > iter = vo.getChildren().listIterator();
    const iter = treeNode.children[Symbol.iterator]();
    // while (!iter.done) {
    for (let i = 0; i < treeNode.children.length; i++) {
      // TreeGeneratorResponseVO region = iter.next();
      const region = treeNode.children[i];
      /*check for parent visibility for every child*/
      if (parentShowMap.get(treeNode.child) != null &&
        parentShowMap.get(treeNode.child)) {
        region.visibleDefault = true;
        // parentShowMap.put(region.getChild(), true);
        parentShowMap.set(region.child, true);
      }
      if (this.recursiveFilter(region, region.children, parentShowMap, searchString, column)) {
        // vo.setExpanded(true);
        treeNode.expanded = true;
      }
      if (region.visibleDefault) {
        // vo.setvisibleDefault(true);
        treeNode.visibleDefault = true;
      } else {
        treeNode.visibleDefault = false;
      }
    }
    temp.push(treeNode);
    return temp;
  }

  recursiveFilter(parent, children, parentShowMap, searchString, column) {

    let returnFlag = false;
    let ifAnyChildrenEqual = false;
    /*check for parent visibility and own visibility*/
    if (this.matchSearchCriteria(parent, searchString, column)) {
      parent.visibleDefault = true;
      parentShowMap.set(parent.child, true);
      returnFlag = true;
    }

    if (children && children.length > 0) {

      for (const child of children) {
        /*check for parent visibility for every child*/
        if (parentShowMap.get(parent.child) != null &&
          parentShowMap.get(parent.child)) {
          child.visibleDefault = true;
          parentShowMap.set(child.child, true);
        }
        /*check for child visibility for every child*/
        if (this.recursiveFilter(child, child.child, parentShowMap, searchString, column)) {
          returnFlag = true;
          // make parent expanded if any child is visible
          parent.expanded = true;
        }
        if (parentShowMap.get(child.child) != null &&
          parentShowMap.get(child.child)) {
          ifAnyChildrenEqual = true;
        }
      }

      if (ifAnyChildrenEqual) {
        parent.visibleDefault = true;
        parentShowMap.set(parent.child, true);
      }
    }


    return returnFlag;
  }

  matchSearchCriteria(treeNode, nameSearch, hrcy) {
    if (nameSearch && treeNode.name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1) {
      return false;
    }
    if (hrcy && treeNode.hrcyStr.toLowerCase().indexOf(hrcy.toLowerCase()) === -1) {
      return false;
    }
    return true;
  }

  /////// Second filter ///////////
  updateFilterItems(treeArray, name, hrcy) {
    let items = _.cloneDeep(treeArray);
    let _this = this;
    if (name !== '' || hrcy !== '') {
      let filterItems_1 = [];
      // let filterText_1 = filterText.toLowerCase();
      items.forEach(function (item) {
        let newItem = _this.filterItem(item, name, hrcy);
        if (!_.isNil(newItem)) {
          filterItems_1.push(newItem);
        }
      });
      return filterItems_1;
    } else {
      return items;
    }
    // this.updateCheckedOfAll();
  }

  filterItem(item, name, hrcy) {
    let _this = this;
    // const isMatch = _.includes(item[column].toLowerCase(), filterText);
    const isMatch = this.matchSearchCriteria(item, name, hrcy);
    if (isMatch) {
      return item;
    } else {
      if (!_.isNil(item.children)) {
        let children_1 = [];
        item.children.forEach(function (child) {
          let newChild = _this.filterItem(child, name, hrcy);
          if (!_.isNil(newChild)) {
            children_1.push(newChild);
          }
        });
        if (children_1.length > 0) {
          /*  let newItem = new FilterTreeviewItem(item);
           newItem.collapsed = false;
           newItem.children = children_1;
           return newItem; */
          // let newItem = new FilterTreeviewItem(item);
          item.expanded = true;
          item.children = children_1;
          return item;
        }
      }
    }
    return undefined;
  }

  childCheckbox (items, checked) {
    items.forEach(item => {
      if (!_.isNil(item.children)) {
        this.childCheckbox(item.children, checked);
        item.checked = item.every(child => child.selected);
      }
    });
  }

}
