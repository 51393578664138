export class MassMenuItemUpdateModel {
	online = {
			selection: {
				miList: [],
				miUpdateType: 0,
				formDirty: false,
				isReadOnly: false,
				disableUpdate: false,
				disableNext: false,
				layeringLogicType: ''
			},
			updateAndReview: {
				table: [],
				tableCopy: [],
				showTable: false,
				showUpdateTable: false,
				showResultTable: false,
				allowNameValidation: true,
				noRecords: true,
				disableCancel: false,
				disableTableHeaderInput: true,
				filter: {
					'productCode': '',
					'productName': '',
					'languageId': '',
					'languageName': '',
					'deviceId': '',
					'shortName': '',
					'driveName': '',
					'csoName': '',
					'codName': '',
					'alternateName': '',
					'shortMonitorName': '',
					'summaryMonitorName': '',
					'longName': '',
					'csoSizeName': '',
					'csoGenericName': ''
				},
				clearFilter: {
					'productCode': '',
					'productName': '',
					'languageId': '',
					'languageName': '',
					'deviceId': '',
					'shortName': '',
					'driveName': '',
					'csoName': '',
					'codName': '',
					'alternateName': '',
					'shortMonitorName': '',
					'summaryMonitorName': '',
					'longName': '',
					'csoSizeName': '',
					'csoGenericName': ''
				},
				sortAscending: {
					'productCode': false,
					'productName': false,
					'productInstId': false,
					'languageId': false,
					'languageName': false,
					'deviceId': false,
					'shortName': false,
					'driveName': false,
					'csoName': false,
					'codName': false,
					'alternateName': false,
					'shortMonitorName': false,
					'summaryMonitorName': false,
					'longName': false,
					'csoSizeName': false,
					'csoGenericName': false
				}
			},
			results: {
				table: [],
				tableCopy: [],
				filterNumber: [],
				filterName: [],
				filterLanguage: [],
				filterResults: [],
				filterMessage: [],
				filterEffectiveDate: [],
				filterNumberCopy: [],
				filterNameCopy: [],
				filterLanguageCopy: [],
				filterResultsCopy: [],
				filterMessageCopy: [],
				filterSettings: {},
				sortAscending: {
					'productCode': false,
					'productName': false,
					'languageName': false,
					'result': false,
					'message': false,
					'effectiveDate': false,
				},
				filter: {
					'productCode': '',
					'productName': '',
					'languageName': '',
					'result': '',
					'message': '',
					'effectiveDate': ''
				}
			}
	};
}
