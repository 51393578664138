//import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
//import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageModule } from 'angular-2-local-storage';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from './utilities/ngx-pagination';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularMultiSelectModule } from './utilities/angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { AppComponent } from './app.component';
import { DataTransferComponent } from './components/data-transfer/data-transfer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './services/interceptor';
import { AuthGuard } from './services/auth-guard.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpCoreService } from './services/http-core.service';
import { HeaderComponent } from './components/header/header.component';
import { RestaurantFilterPipe } from './utilities/filter/restaurant-filter.pipe';
import { MenuLazyLoadFilterPipe } from './utilities/filter/menu-lazy-load-filter.pipe';
import { MenuLazyLoadFilterReportPipe } from './utilities/filter/menu-lazy-load-filter-report.pipe';
import { TreeFilterPipe } from './utilities/filter/tree-filter.pipe';

import { DropdownService } from './services/dropdown.service';
import { TreeHelperService } from './services/tree-helper.service';
import { DataService } from './models/dataservice';
import { CommonService } from './services/common.service';
import { AuthUserService } from './services/auth-user.service';
import { SelectiveStrategy } from './services/selective-strategy.service';
import { SessionLiveServiceService } from './services/session-live-service.service';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { BatchSchedulingService } from './components/package/batch-scheduling/services/batch-scheduling-service';
import { HeaderService } from './services/header.service';
import { SelectedLangService } from './services/selected-lang.service';
import { SidebarModule } from 'ng-sidebar';
import { SharedModule } from './layout/shared-modules/shared.module';
import { DialogModule } from 'primeng/dialog';
import { PackageScheduleService } from './components/package/services/package-schedule.service';
import { FooterModule } from './components/footer/footer.module';
import { PackageModule } from './components/package/package.module';
import { DataTransferModule } from './components/data-transfer/data-transfer.module';
import { MassPromotionUpdateModule } from './components/masspromotionupdate/masspromotionupdate.module';
import { ArrayLimitPipe } from './pipes/arrayLimit';
//import { ActionItemModule } from './components/action-item/action-item.module';
import { OwnerOperatorAuthGuard } from './shared/guard/owner-operator-auth.guard';
import { LayoutModule } from './layout/layout.module';
import { OwnerOperatorHeaderModule } from './layout/shared-modules/owner-operator-header/owner-operator-header.module';
import { OwnerOperatorMenuModule } from './layout/shared-modules/owner-operator-menu/owner-operator-menu.module';
import { PermissionExpressionEvaluatorService } from './services/permission-expression-evaluator.service';
import { MiFilterSharedPipe } from './shared/pipes/menu-item-filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// routes
export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuard,
      OwnerOperatorAuthGuard
    ],
    data: { preload: true },
    loadChildren: './layout/layout.module#LayoutModule'
  },
  {
    path: 'access-denied',
    data: { preload: true },
    loadChildren: './access-denied/access-denied.module#AccessDeniedModule'
  },
  {
    path: 'dashboard',
    loadChildren: './components/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'generate-audit',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/audit-log/generate-audit-log/generate-audit-log.module#GenerateAuditLogModule'
  },

  {
    path: 'massmenuitemdelete',
    //canActivate: [AuthGuard],
    //data: { preload: true },
    loadChildren: './components/massmenuitemdelete/massmenuitemdelete.module#MassMenuItemDeleteModule'
  },
  {
    path: 'massmenuitemdeletereport',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/massmenuitemdeletereport/massmenuitemdeletereport.module#MassMenuItemDeleteReportModule'
  },
  {
    path: 'massmenuitemdeletereportresults',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/massmenuitemdeletereportresults/massmenuitemdeletereportresults.module#MassMenuItemDeleteReportResultsModule'
  },
  {
    path: 'restaurantdbparameter',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/restaurant-db-parameter/restaurant-db-parameter.module#RestaurantModule'
  },
  {
    path: 'generatePTA',
    //canActivate: [AuthGuard],
    //data: { preload: true },
    loadChildren: './components/generate-pta/generate-pta.module#GeneratePTAModule'
  },
  {
    path: 'mass-menu-item-update',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/mass-menu-item-update/mass-menu-item-update.module#MassMenuItemUpdateModule'
  },
  {
    path: 'masspromotionupdate',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/masspromotionupdate/masspromotionupdate.module#MassPromotionUpdateModule'
  },
  {
    path: 'package',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/package/package.module#PackageModule'
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/reports/reports.module#ReportsModule'
  },
  {
    path: 'batchScheduling',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/package/batch-scheduling/batch-scheduling.module#BatchSchedulingModule'
  },
  {
    path: 'batch-scheduling-confirmation',
    canActivate: [AuthGuard],
    data: { preload: true },
    loadChildren: './components/package/batch-scheduling/batch-scheduling-confirmation/batch-scheduling-confirmation.module#BatchSchedulingConfirmationModule'
  },

  {
    path: 'data-transfer', component: DataTransferComponent
  },


];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    RestaurantFilterPipe,
    MenuLazyLoadFilterPipe,
    TreeFilterPipe,
    MenuLazyLoadFilterReportPipe,
    ArrayLimitPipe,
    MiFilterSharedPipe
  ],
  imports: [
    //BrowserModule,
    HttpClientModule, HttpModule, SharedModule,
    //BrowserAnimationsModule,
    NoopAnimationsModule,
    Ng2SearchPipeModule,
    DialogModule,
    //ActionItemModule,
    DataTransferModule, FormsModule,
    OrderModule, NgxPaginationModule,
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'top'
    }), SidebarModule.forRoot(),
    NgbModule.forRoot(), TranslateModule.forRoot(),
    AngularMultiSelectModule,
    StorageServiceModule,
    LocalStorageModule.withConfig({
      prefix: 'my-app',
      storageType: 'localStorage'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }), Ng4LoadingSpinnerModule.forRoot(),
    FooterModule,
    PackageModule,
    MassPromotionUpdateModule,
    OwnerOperatorHeaderModule,
    OwnerOperatorMenuModule,
    LayoutModule,
    InfiniteScrollModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true
  }, HttpCoreService,

    PackageScheduleService,
    DropdownService,
    AuthUserService,
    AuthGuard,
    CookieService,
    SelectiveStrategy,
    TreeHelperService,
    SessionLiveServiceService,
    DataService,
    CommonService,
    BatchSchedulingService,
    HeaderComponent,
    SelectedLangService,
    HeaderService,
    PermissionExpressionEvaluatorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}