
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import {finalize, catchError, tap} from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';



import {LocalStorageService} from 'angular-2-local-storage';
import { SessionLiveServiceService } from './session-live-service.service';
import { AuthUserService } from './auth-user.service';

/**
 * Interceptor used to intercept HTTP request and response
 * @export
 * @class AuthInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(@Inject(SESSION_STORAGE) public sessionStorage: StorageService, private localStorageService: LocalStorageService,
  public sessionAliveService: SessionLiveServiceService, private authService: AuthUserService) {}
/**
 * Method used for intercepting HTTP request and response
 * @param {HttpRequest<any>} request
 * @param {HttpHandler} next
 * @returns {Observable<HttpEvent<any>>}
 * @memberof AuthInterceptor
 */
intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    let accessToken = this.authService.getAuthToken();

    request = request.clone({
      withCredentials: true,
      setHeaders: {
        'Authorization':  accessToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Request-Method':'POST'
      }
    });
    return next
      .handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
        }
        return ev;
      }),
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
        }
        return observableThrowError(response);
      }),
      finalize(() => {
        //this.sessionAliveService.getSessionLive();
      }),)
  }
}
