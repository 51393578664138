import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpCoreService } from './http-core.service';
import * as _ from 'lodash';
import { isArray } from 'lodash';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';



@Injectable()
export class DropdownService {
	// Common setting for all dropdowns.

	selectStatusDropdownSettings = {
		singleSelection: false,
		idField: 'type',
		textField: 'value',
		selectAllText: 'Select All',
		unSelectAllText: 'Unselect All',
		itemsShowLimit: 0,
		allowSearchFilter: false
	  };
	  
	selectMenuDropdownSettings = {
		singleSelection: false,
		idField: 'type',
		textField: 'value',
		selectAllText: 'Select All',
		unSelectAllText: 'Unselect All',
		itemsShowLimit: 0,
		allowSearchFilter: false
	  };

	dropDownSettings = {
			singleSelection: false,
			text: 'All',
			selectAllText: 'All',
			unSelectAllText: 'Unselect All',
			enableSearchFilter: true,
			classes: 'myclass custom-class',
	};

	dropDownSingleSettings = {
			singleSelection: true,
			text: ' ',
			selectAllText: ' ',
			unSelectAllText: 'Unselect All',
			enableSearchFilter: true,
			classes: 'myclass custom-class'
	};

	dropDownResultTableSettings = {
		singleSelection: false,
		text: 'All',
		selectAllText: 'All',
		unSelectAllText: 'Unselect All',
		enableSearchFilter: true,
		classes: 'myclass custom-class'
};

dropdownSelectRestaurantsSettings = {
	singleSelection: false,
	idField: 'id',
	textField: 'itemName',
	selectAllText: 'Select All',
	unSelectAllText: 'Unselect All',
	itemsShowLimit: 0,
	allowSearchFilter: false
  };

  dropdownSelectSectionsSettings = {
	singleSelection: false,
	idField: 'id',
	textField: 'itemName',
	selectAllText: 'Select All',
	unSelectAllText: 'Unselect All',
	itemsShowLimit: 0,
	allowSearchFilter: false
  };
  
	constructor(public localStorageService: LocalStorageService,
			public httpCoreService: HttpCoreService,
			@Inject(SESSION_STORAGE) private sessionStorage: StorageService) { }

	public getDropdownData(endPoint) {
		return this.httpCoreService.getHttpUrl(endPoint, this.dropdownRequestData(), 'post');
	}

	public getFirstDropdownData(data) {
		if (data.Level1 && data.Level1.length) {
			const tempArray = [];
			const length = data.Level1.length;
			for (let i = 0; i < length; i++) {
				tempArray.push(data.Level1[i].nodeDetails);
			}
			if (data.Level0 && isArray(data.Level0) && data.Level0.length > 0) {
				tempArray.unshift(data.Level0[0].nodeDetails);
			}
			return this.mapDropdownData(tempArray, 'Level1');
		}
	}

	public getSecondDropdownData(data, firstDropDownValues) {
		if (data.Level2 && data.Level2.length) {
			const tempArray = [];
			const length = data.Level2.length;
			for (let i = 0; i < length; i++) {
				if (firstDropDownValues && _.findIndex(firstDropDownValues, { 'itemName': data.Level2[i].nodeDetails.Level1.trim()}) !== -1) {
					const tempObj = data.Level2[i].nodeDetails;
					tempObj.hrchy =  data.Level2[i].nodeDetails.Level1;
					// tempArray.push(data.Level2[i].nodeDetails);
					tempArray.push(tempObj);
				}else if (!firstDropDownValues) {
					tempArray.push(data.Level2[i].nodeDetails);
				}
			}
			return this.mapDropdownData(tempArray, 'Level2');
		}
	}

	public getThirdDropdownData(data, secondDropDownValues) {
		if (data.Level3 && data.Level3.length) {
			const tempArray = [];
			const length = data.Level3.length;
			for (let i = 0; i < length; i++) {
				if (secondDropDownValues && _.findIndex(secondDropDownValues, { 'itemName': data.Level3[i].nodeDetails.Level2.trim()}) !== -1) {
					// adding the heirarchy
					const tempObj = data.Level3[i].nodeDetails;
					tempObj.hrchy =  data.Level3[i].nodeDetails.Level1 + '>>' + data.Level3[i].nodeDetails.Level2;
					tempArray.push(tempObj);
				}else if ( !secondDropDownValues ) {
					tempArray.push(data.Level3[i].nodeDetails);
				}
			}
			return this.mapDropdownData(tempArray, 'Level3');
		}
	}

	public  createDropDownSelectionArrayForSearch(dropDownSelection, key, array) {
		if (dropDownSelection && dropDownSelection.length) {
			dropDownSelection.forEach(element => {
				const tempObject = {
						name: '',
						value: '',
						hierarchy :''
				};
				tempObject.name = key;
				tempObject.value = element.itemName;
				tempObject.hierarchy = element.hrchy;
				array.push(tempObject);
			});
		}
		return array;
	}

	public mapDropdownData (data, levelName) {
		const tempArray = [];
		data.forEach(item => {
			let tempItem = _.cloneDeep(item);
			// const tempObject = {
			// id : '',
			// itemName : ''
			// };
			// tempObject.id = item.node;
			// tempObject.itemName = item[levelName];
			// tempArray.push(tempObject);
			tempItem.itemName = item[levelName];
			tempItem.id = item.node;
			tempArray.push(tempItem); 
		});
		return tempArray;
	}

	public dropdownRequestData () {
		const userDetails = this.getValidationDetail();
		const marketIdList = [];
		marketIdList.push(userDetails.mkt);
		const treeRequest = {
				'marketIdList': marketIdList,
				'userId': userDetails.eid,
				'localeLangID': '1',
				'moduleName': 'USER',
				'searchString': null,
				'searchOption': null,
				'selectByID': null,
				'isSearchRequired': false
		};
		return treeRequest;
	}

	getValidationDetail() {
		let eid = '';
		let marketId = '';
		if (this.localStorageService.get('userEid')) {
			eid = this.localStorageService.get('userEid');
		} else{
			eid = this.sessionStorage.get('userEid');
		}
		if (this.localStorageService.get('marketId')) {
			marketId = this.localStorageService.get('marketId');
		} else {
			marketId = this.sessionStorage.get('marketId');
		}
		return { eid: eid, mkt: marketId };
	}
}
