import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from '../services/auth-guard.service';
import { OwnerOperatorAuthGuard } from '../shared/guard/owner-operator-auth.guard';
import { MessageCreationGuard } from './message-creation/services/message-creation.guard';
import { Permissions } from '../shared/guard/auth-gaurd-permissions';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'owner-operator-homepage', pathMatch: 'prefix' },
      {
        path: 'owner-operator-homepage',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          permissionExpression: Permissions.OOPHomePermission
        },
        loadChildren: './owner-operator-homepage/owner-operator-homepage.module#OwnerOperatorHomepageModule'
      },
      {
        path: 'packagegeneration',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.PackageGenerationPermission
        },
        loadChildren: './package-generation/package-generation.module#PackageGenerationModule'
      },
      {
        path: 'generateReport',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.GenerateReportPermission
        },
        loadChildren: './generate-report/generate-report.module#ReportModule'
      },
      {
        path: 'pta',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.PTAPermission
        },
        loadChildren: './pta/pta.module#PTAModule'
      },
      {
        path: 'pos',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.POSLayoutPermission
        },
        loadChildren: './PosLayoutDetails/pos-layout-module.module#PosLayoutModuleModule'

      },
      {
        path: 'menu-item-inactivation',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.MIIPermission
        },
        loadChildren: './menu-item-inactivation/mii.module#MenuItemInactivationModule'
      },

      {
        path: 'message-management',
        canActivate: [AuthGuard
          //OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          //permissionExpression: Permissions.m
        },
        loadChildren: './message-creation/message-creation-admin.module#MessageCreationAdminModule'
      },
      {
        path: 'action-item',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: true,
          permissionExpression: Permissions.ActionItemAdminPermission
        },
        loadChildren: './action-item/action-item-admin.module#ActionItemModule'
      },
      {
        path: 'owneroperatorview',
        canActivate: [AuthGuard],
        loadChildren: './owner-operator-overview/owner-operator-overview.module#OwnerOperatorOverviewModule'
      },
      {
        path: 'mass-mi-delete',
        canActivate: [AuthGuard,
          OwnerOperatorAuthGuard
        ],
        data: {
          preload: false,
          permissionExpression: Permissions.MassMIDeletePermission
        },
        loadChildren: './mass-mi-delete/mass-mi-delete.module#MassMIDeleteModule'

      },
      {
        path: 'dimension-group',
        canActivate: [AuthGuard],
        data: {
          preload: true,
          permissionExpression: Permissions.DimensionGroupPermission
        },
        loadChildren: './dimension-group/dimension-group.module#DimensionGroupModule'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, OwnerOperatorAuthGuard]
})
export class LayoutRoutingModule { }
