export const ACTION_CONST = {
  /**
 * 
 * Left Selection Panel Constants
 * 
 */

  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  MINUS_ONE: -1,
  ENTER_KEY: 13,
  PTA_ACTION_ITEM: 1,
  NUMBER_OF_MONTHS_DISPLAY: 2,
  FIFTY: 50,
  EIGHTY:80,
  SIXTY:60,
  SUBJECT_TEXT_LIMIT_MOBILE: 60,
  SUBJECT_TEXT_LIMIT_DESKTOP: 80,
  MENU_ITEM_TEXT_LIMIT: 50,
  RECIPIENT_NODE_TEXT_LIMIT_MOBILE:  50,
  RECIPIENT_NODE_TEXT_LIMIT_DESKTOP: 60,


  /* popup button constants */
  NO_BUTTON: 'No',
  OK_BUTTTON: 'Ok',
  YES_BUTTON: 'Yes',
  CLOSE_EVENT: 'closeEvent',
  YES: 'yes',
  FAIL: 'fail',
  SUCCESS: 'success',

  /*URL Constants */
  ACTION_ADMIN_URL: '',
  ACTION_CREATE_URL: 'create',
  ACTION_VIEW_URL: 'view',
  ACTION_EDIT_URL: 'edit',
  ACTION_CONFIRMATION_URL: 'confirmation',
  SELF_URL: '_self',

  ACTION_ADMIN_ROUTE: '/action-item',
  ACTION_CREATE_ROUTE: '/action-item/create',
  ACTION_VIEW_ROUTE: '/action-item/view',
  ACTION_CONFIRMATION_ROUTE: '/action-item/confirmation',
  EDIT_ROUTE: '/action-item/edit',
  ACTION_EDIT_ROUTE: '/action-item/edit?isEdit=true',
  OOP_HP_ROUTE: '/owner-operator-homepage',
  SELF_ROUTE: '/',

  /*Action Item constants*/
  EMPTY_STRING: '',
  EMPTY_SPACE: ' ',
  HYPHON: '-',
  PENDING: 'Pending',
  DELIVERED: 'Delivered',
  REST: "rest",
  MENU: "menu",
  SEND_DATE: 'sendDate',
  SEARCH_MENU: 'searchMenu',
  ACTION_SUBJECT: 'actionItemSubject',
  ACTION_TYPE: 'actionItemTypes',
  ACTION_RECIPIENT: 'recipientNodesStr',
  ACTION_STATUS: 'statusName',
  TYPE_NAME: 'typeName',
  CUSTOM_FIELD: 'custom-field',
  PROD_CODE: 'productCode',
  PROD_DESC: 'productDesc',
  REST_NUM: 'restNumber',
  REST_NAME: 'restName',
  REST_ITEM_SET: 'restSet',
  REST_SET_JOIN: 'restSetJoin',
  REST_STR: 'restStrDsply',
  MENU_STR: 'menuStrDsply',
  MENU_ITEM_SET: 'menuItemSet',
  PTA: "Price Tax Activate",
  MII: "Menu Item Inactivation",
  NAVIGATION: 'arrows',
  OUTSIDE_DAYS: 'visible',
  SND_MAIL: "sendMail",
  PDF: '.PDF',
  export_Url: "generateActionItemPDF",
  SELECT_ONE: "Select One",
  FAILED: 'Failed',
  SUBMITTED: 'Submitted',
  TIMEINTERVAL:30,
  FAILED_STATUS:'4',


  /* Session Storage Constant */
  MSG_MAP_KEY: 'messageMap',
  TIME_ZONE_KEY: 'timeZone',
  EFF_DATE_KEY: 'effectiveDate',
  FORMATED_DATE: 'formattedDt',
  DATE_FORMAT_KEY: 'selectedDateFormat',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_DD_YYYY: "MM/DD/YYYY",
  TABLE_VIEW: 'R',
  TREE_VIEW: 'notR',
  USER_ID_KEY: 'userId',
  USER_EID_KEY: 'userEid',
  MARKET_ID_KEY: 'marketId',
  LOCALE_LANG_KEY: 'localeLangID',
  USER_DETAILS_KEY: 'userDetails',
  PERMISSION_MAP_KEY: 'permissionMap',
  ACTION_ITM_CREATE: 'actionItemCreate',
  REST_NODE_LIST_KEY: 'restNodeList',


  ACTIONTYPELOOKUP_API_NAME: 'getMenuItemListNoStatus',
  ACTIONITEM_API_NAME: 'getActionItems',
  REST_API_NAME: 'getRestaurantListByUser',
  MENU_LIST_PARAM_KEY: 'getValueFromGlobalParam',
  MARKET_DATE_KEY: 'getCurrentDateFromUser',
  EMP_DT_ERR_MSG: 'Date should not be empty',
  REST_CHKBOX_ID: 'restSelectionCheckbox1',
  POP_REST_CHANGE_HEADER: 'Price Tax Activate Warning',
  POP_REST_CHANGE_BODY: 'If you change restaurant selections, all pricing information will be lost.',

  TRUE: true,
  FALSE: false,
  NULL: null,
  UNDEFINED: 'undefined',

  //adobe analytics constant
  ActionItem: "Action Item",
  ActionItemPage: "Action Item Management",
  ActionItemCreation: "Action Item Creation",
  ActionItemEdition: "Action Item Edit",
  ClickName: "Create Action Item",
  PTAInterim: "PTA Interim",
  PTAInterimPage: "Price Tax Activate: Direct",
  InterimNext: "Next",
  InterimRemove: "Remove",
  ClickSave: "Save",
  ClickCancel: "Cancel",
  ClickSend: "Send",
  MIIInterim: "MII Interim",
  MIIInterimPage: "Menu Item Inactivation: Direct",
  ClickSendMail: "sendMail",
  ClickCancelMail: "cancelMail",
  InterimClear: "Clear",
  InactivateEventYesConfirmation: "inactivateEventYesConfirmation",
  CancellationConfirmation:"Cancellation Confirmation",
  ActionItemViewPage: 'Action Item Management: Action Item View',
  ActionItemEditSearchClick:'Action Item Management-Edit : Restaurant Selection',
  ActionItemCreateSearchClick:'Action Item Management-Create : Restaurant Selection',
  Yes: "Yes",
  analyticsData: {
    ActionItemEditMessege: { 
        pageName: "Action Item Management: Action Item Edit",
        pageType: 'Modal',
        pageUrl:'',                    
        events: 'event19',
        clickName: 'Cancel',
        clickTrackSection: 'Content',
        linkType: 'CTA',
    },
    ActionItemCreateMessage: { 
      pageName: "Action Item Management: Action Item Create",
      pageType: 'Modal',
      pageUrl:'',                    
      events: 'event19',
      clickName: '',
      clickTrackSection: 'Content',
      linkType: 'CTA',
  },
  ActionItemOnSearch: { 
    pageName: "Action Item Management",
    pageType: 'Modal',
    pageUrl:'',                    
    events: 'event19',
    clickName: '',
    clickTrackSection: 'Content',
    linkType: 'CTA',
  },
  ActionItemSearchClick: { 
    pageName: "",
    pageType: 'Modal',
    pageUrl:'',                    
    events: 'event19',
    clickName: 'Search',
    clickTrackSection: 'Content',
    linkType: 'CTA',
},
ActionItemTypeDropdown: { 
  pageName: "Action Item Management",
  pageType: 'Modal',
  pageUrl:'',                    
  events: 'event19',
  clickName: '',
  clickTrackSection: '',
  linkType: 'Dropdown',
},
ActionItemStatusClick: { 
  pageName: "Action Item Management",
  pageType: 'Modal',
  pageUrl:'',                    
  events: 'event19',
  clickName: 'Status',
  clickTrackSection: 'Content',
  linkType: 'HyperLink',
  addInfo:''
},
},

RECORD_TYPE_AI: 2,
RECORD_TYP_AI_REQ: 1
}