import { Component, OnInit } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { User } from '../../models/user';
import { connection } from '../../../environments/connection.properties';
import { SelectedLangService } from '../../services/selected-lang.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Http, RequestOptions, Headers } from '@angular/http';

import * as moment from 'moment';
import 'moment-timezone';
declare var $: any;
import { appconstant } from '../../../environments/app.constant';
import { AuthUserService } from '../../services/auth-user.service';
import { HeaderService } from '../../services/header.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	providers: [SelectedLangService]
})
@Injectable()
export class HeaderComponent implements OnInit {
	msg: any;
	permissionMap: any[];
	userDetails: string;
	currentDate = '';
	lang: string;
	userLanguage = '';
	userEid = '';
	token: string;
	urlToCall = '';
	translate: any;
	functionId = '';
	helpFileId = 0;
	marketId: number;
	localeLangID: any;
	marketName: string;
	isChangeMadeCast: boolean;
	modelHeader = '';
	isVisitedOOP: boolean = false;
	constructor(
		private localStorageService: LocalStorageService,
		private selectedLangService: SelectedLangService,
		public authUserService: AuthUserService,
		private headerService: HeaderService,
		@Inject(SESSION_STORAGE) private sessionStorage: StorageService,
		private http: Http,
		private router: Router) { }

	ngOnInit() {
		this.headerService.isChangeMadeCast.subscribe(cast => this.isChangeMadeCast = cast);

		console.log("HeaderComponent");

		let timeZone = '';
		let storageUndefined = false;
		if (this.sessionStorage.get('isSessionStorage') == "true" || this.sessionStorage.get('isSessionStorage') == true) {

			timeZone = this.sessionStorage.get('timeZone');

			if (timeZone == undefined || timeZone == '' || timeZone == null) {
				timeZone = 'GMT';
				storageUndefined = true;
			} else {
				storageUndefined = false;
			}


			setInterval(() => {
				timeZone = this.sessionStorage.get('timeZone');
				storageUndefined = false;

				this.currentDate = moment().tz(timeZone).format('LT');
			}, 1000);

			if (this.sessionStorage.get('userDetails') != null) {
				this.userDetails = this.sessionStorage.get('userDetails');
			}
			if (this.sessionStorage.get('localeLangDesc') != null) {
				this.userLanguage = this.sessionStorage.get('localeLangDesc');
			}
			// Setting token value
			this.token = this.sessionStorage.get('token');
			if (this.token == null) {
				this.token = '';
			}
			this.translate = this.selectedLangService.switchLanguage(this.userLanguage);

			this.userEid = this.sessionStorage.get('userEid');

			this.marketName = decodeURIComponent(this.sessionStorage.get('marketName'));

		} else {
			if (this.localStorageService.get('userDetails') && this.localStorageService.get('userDetails')['timeZone']) {
				timeZone = this.localStorageService.get('userDetails')['timeZone'];
				storageUndefined = false;
			} else {
				timeZone = 'GMT';
				storageUndefined = true;
			}

			setInterval(() => {
				// Updating the timezone after local storage.
				if (storageUndefined && this.localStorageService.get('userDetails') && this.localStorageService.get('userDetails')['timeZone']) {
					timeZone = this.localStorageService.get('userDetails')['timeZone'];
					storageUndefined = false;
				}
				this.currentDate = moment().tz(timeZone).format('LT');
			}, 1000);

			if (this.localStorageService.get('userDetails') != null) {
				this.userDetails = this.localStorageService.get('userDetails');
			}
			if (this.localStorageService.get('localeLangDesc') != null) {
				this.userLanguage = this.localStorageService.get('localeLangDesc');
			}
			// Setting token value
			this.token = this.localStorageService.get('token');
			if (this.token == null) {
				this.token = '';
			}
			this.translate = this.selectedLangService.switchLanguage(this.userLanguage);

			this.userEid = this.localStorageService.get('userEid');

			this.marketName = this.localStorageService.get('marketName');



		}


	}

	exit() {
		if (this.isChangeMadeCast || this.sessionStorage.get('promoDataChangedFlag') == "true") {
			this.modelHeader = '#modal-question-header';
		} else {
			this.modelHeader = '';
			this.goToRFM();
		}
	}

	/**
	Function to navigate from Ang4 app to RFM Online App
	passing param : token
	**/

	goToRFM() {

		if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH === 'true') {
			this.urlToCall = location.protocol + '//' + location.hostname + ':' +
				connection.RELATIVE_API_PORT + connection.RFM_HOME_API_PATH;
		} else {
			this.urlToCall = connection.RFM_HOME_FULL_URL;
		}


		if (this.sessionStorage.get('isSessionStorage') == "true" || this.sessionStorage.get('isSessionStorage') == true) {
			if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH === 'true') {
				if (connection.HEADER_ENV === 'AWSRDI') {
					this.sessionStorage.set("isSessionStorage", "false");
					window.location.href = this.sessionStorage.get("ptaURL")
						+ '/rfm2OnlineApp/rfmLogin.action?'
						+ 'iv-user=' + this.sessionStorage.get('userEid')
						+ '&paramLogin=1'
						+ '&refresh=yes'
						+ '&isVisitedOOPPage=' + this.isVisitedOOP;
				} else {
					this.sessionStorage.set("isSessionStorage", "false");
					window.location.href = this.sessionStorage.get("ptaURL")
						+ '/rfm2OnlineApp/rfmLogin.action?'
						+ 'isVisitedOOPPage=' + this.isVisitedOOP;
				}
			} else {
				this.sessionStorage.set("isSessionStorage", "false");
				window.location.href = this.urlToCall
					+ '/rfm2OnlineApp/rfmLogin.action?'
					+ 'iv-user=' + this.sessionStorage.get('userEid')
					+ '&paramLogin=TEST-RDI'
					+ '&refresh=yes'
					+ '&isVisitedOOPPage=' + this.isVisitedOOP;
			}

			this.localStorageService.clearAll();
		} else {
			if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH === 'true') {
				if (connection.HEADER_ENV === 'AWSRDI') {
					window.location.href = this.localStorageService.get("ptaURL")
						+ '/rfm2OnlineApp/rfmLogin.action?'
						+ 'iv-user=' + this.localStorageService.get("userEid")
						+ '&paramLogin=1'
						+ '&isVisitedOOPPage=' + this.isVisitedOOP;
				} else {
					window.location.href = this.localStorageService.get("ptaURL")
						+ '/rfm2OnlineApp/rfmLogin.action?'
						+ 'isVisitedOOPPage=' + this.isVisitedOOP;
				}

			} else {
				window.location.href = this.urlToCall
					+ '/rfm2OnlineApp/rfmLogin.action?'
					+ 'iv-user=' + this.localStorageService.get("userEid")
					+ '&paramLogin=TEST-RDI'
					+ '&isVisitedOOPPage=' + this.isVisitedOOP;
			}
			this.localStorageService.clearAll();

		}

	}

	/**
	* This method exposes the functionality of retrieving Help file for a particular function.
	*/
	getOnlineHelp() {
		if (this.sessionStorage.get('isSessionStorage') == "true" || this.sessionStorage.get('isSessionStorage') == true) {
			this.functionId = this.getFunctionId(this.router.url);
			this.localeLangID = this.sessionStorage.get('localeLangID');
			this.marketId = this.sessionStorage.get('marketId');
			let reqData = "getOnlineHelp?functionId=" + this.functionId + "&helpFileId=" + this.helpFileId + "&userEid=" + this.userEid + "&marketId=" + this.marketId + "&localeLangID=" + this.localeLangID;
			let onlineResp: any;
			this.authUserService.getOnlineHelpData(reqData + "&check=true", "").subscribe((data) => {
				onlineResp = data;
				if (onlineResp != null && onlineResp.success == false) {
					this.msg = "Help Guide not found.";
					this.sessionStorage.set(this.functionId, true);
					this.sessionStorage.set("onlineHelpMsg", 'Help Guide not found.');
				}
				else {
					let url = '';

					if (connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true') {
						url = location.protocol + "//" + location.hostname + ":" +
							connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
					} else {
						url = connection.API_FULL_URL;
					}
					var params = {
						'Authorization': `${this.localStorageService.get('accessToken')}`
					};
					const getOnlineHelpUrl = url + reqData + '&check=false';
					var urlOpen = [getOnlineHelpUrl, $.param(params)].join('&');
					window.open(urlOpen, '_blank');

				}
			});
		} else {
			this.functionId = this.getFunctionId(this.router.url);

			this.localeLangID = this.localStorageService.get('localeLangID');
			this.marketId = this.localStorageService.get('marketId');
			let reqData = "getOnlineHelp?functionId=" + this.functionId + "&helpFileId=" + this.helpFileId + "&userEid=" + this.userEid + "&marketId=" + this.marketId + "&localeLangID=" + this.localeLangID;
			let onlineResp: any;
			this.authUserService.getOnlineHelpData(reqData + "&check=true", "").subscribe((data) => {
				onlineResp = data;
				if (onlineResp != null && onlineResp.success == false) {
					this.msg = "Help Guide not found.";
					this.localStorageService.set(this.functionId, true);
					this.localStorageService.set("onlineHelpMsg", 'Help Guide not found.');
				}
				else {
					let url = '';

					if (connection.RELATIVE_PATH == "true" || connection.RELATIVE_PATH == 'true') {
						url = location.protocol + "//" + location.hostname + ":" +
							connection.RELATIVE_API_PORT + connection.RELATIVE_API_PATH;
					} else {
						url = connection.API_FULL_URL;
					}
					var params = {
						'Authorization': `${this.localStorageService.get('accessToken')}`
					};
					const getOnlineHelpUrl = url + reqData + '&check=false';
					var urlOpen = [getOnlineHelpUrl, $.param(params)].join('&');
					window.open(urlOpen, '_blank');

				}
			});
		}
	}

	getFunctionId(path) {
		console.log("getFunctionId " + path);

		if (path === '/generate-audit') {
			return appconstant.AUDIT_LOG_REPORT_FUNC_ID;
		} else if (path.indexOf('/massmenuitemdelete') !== -1) {
			return appconstant.MASS_MENU_ITEM_DELETION_FUNC_ID;
		} else if (path === '/package/create') {
			return appconstant.PKG_SCHEDULE_FUNC_ID;
		} else if (path === '/package/list') {
			return appconstant.PKG_SCHEDULE_FUNC_ID;
		} else if (path === '/reports/list') {
			return appconstant.VIEW_GENERATED_REPORTS_FUNC_ID;
		} else if (path.indexOf('/reports/generate') !== -1) {
			return appconstant.VIEW_AUDIT_LOG_DETAILS_ID;
		} else if (path.indexOf('/reports/generate-restaurant-db') !== -1) {
			return appconstant.REST_DB_PARAMETER_CUSTOMIZATION_FUNC_ID;
		} else if (path.indexOf('/mass-menu-item-update') !== -1) {
			return appconstant.MASS_MENU_ITEM_UPDATE_FUNC_ID;
		} else if (path.indexOf('/masspromotionupdate') !== -1) {
			return appconstant.MASS_PROMOTION_UPDATE_FUNC_ID;
		} else if (path.indexOf('/generatePTA') !== -1) {
			return appconstant.GENERATE_PTA_FUNC_ID;
		} else if (path.indexOf('/restaurantdbparameter') !== -1) {
			return appconstant.REST_DB_PARAM_FUNC_ID;
		} else if (path.indexOf('/batchScheduling') !== -1) {
			return appconstant.MASTER_BATCH_PKG_SCHEDULE_FUNC_ID;
		} else if (path.indexOf('/packagegeneration') !== -1) {
			return appconstant.PACKAGE_GENERATION_GENERATE_NEW_PACKAGE;
		}  else if (path.indexOf('/pta') !== -1) {
			return appconstant.PRICE_TAX_ACTIVATION_FUNC_ID;
		} else if (path.indexOf('/message-management/view-message') !== -1) {
			return appconstant.MESSAGE_CREATION_ADMIN_FUNC_ID;
		} else if (path.indexOf('/message-management/messages') !== -1) {
			return appconstant.MESSAGE_VIEW_OOP_FUNC_ID;
		} else if (path.indexOf('/message-management/create-message') !== -1) {
			return appconstant.MESSAGE_CREATION_ADMIN_FUNC_ID;
		} else if (path.indexOf('/message-management') !== -1) {
			return appconstant.MESSAGE_CREATION_ADMIN_FUNC_ID;
		} else if (path.indexOf('/generateReport') !== -1) {
			return appconstant.OWNER_OPERATOR_HOMEPAGE_REPORTS;
		} else if (path.indexOf('/action-item') !== -1) {
			return appconstant.ACTION_ITEM_MANAGEMENT_FUNC_ID;
		} else if (path.indexOf('/action-item/confirmation') !== -1) {
			return appconstant.ACTION_ITEM_MANAGEMENT_FUNC_ID;
		} else if (path.indexOf('/action-item/create') !== -1) {
			return appconstant.ACTION_ITEM_MANAGEMENT_FUNC_ID;
		} else if (path.indexOf('/action-item/view') !== -1) {
			return appconstant.ACTION_ITEM_MANAGEMENT_FUNC_ID;
		} else if (path.indexOf('/action-item/edit') !== -1) {
			return appconstant.ACTION_ITEM_MANAGEMENT_FUNC_ID;
		} else if (path.indexOf('/owneroperatorview/miiView') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/owneroperatorview/ptaView') !== -1) {
			return appconstant.PRICE_TAX_ACTIVATION_FUNC_ID;
		} else if (path.indexOf('/pos/section') !== -1) {
			return appconstant.OWNER_OPERATOR_HOMEPAGE_POS_LAYOUT;
		} else if (path.indexOf('/menu-item-inactivation/effective-date') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/menu-item-inactivation/select-restaurants') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/menu-item-inactivation/select-restaurant-tree') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/menu-item-inactivation/menu-item') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/menu-item-inactivation/inactivation') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/menu-item-inactivation/confirmation') !== -1) {
			return appconstant.MENU_ITEM_INACTIVATION_FUNC_ID;
		} else if (path.indexOf('/pos/confirm') !== -1) {
			return appconstant.OWNER_OPERATOR_HOMEPAGE_POS_LAYOUT;
		} else if (path.indexOf('/mass-mi-delete') !== -1) {
			return appconstant.MASS_MENUITEM_DELETE_FUNC_ID;
		}
		else if (path.indexOf('/dimension-group') !== -1) {
			return appconstant.DIMENSION_GROUPS_FUNC_ID;
		}
	}

}
