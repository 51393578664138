import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'


@Component({
  selector: 'app-pop-modole-mess',
  templateUrl: './pop-modole-mess.component.html',
  styleUrls: ['./pop-modole-mess.component.scss']
})
export class PopModoleMessComponent implements OnInit {
  @Input() popHeader: string = '';
  @Input() popBody: any;
  @Input() close: any;
  @Input() firstButtonName: any;
  @Input() SecondButtonName: any;
  @Input() firstButtonColor: any;
  @Input() SecondButtonColor: any;
  @Input() CancellationModal: any;
  @Input() firstButtonDisplay:boolean=true;
  @Input() SecondButtonDispaly:boolean=true;

  // 
  @Output() actionName = new EventEmitter();


  modalReference: NgbModalRef;
  activeModal: NgbActiveModal;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private rout: Router,
  ) {

    this.popBody;
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open(content) {
    this.modalService.open(content);
  }

  ngOnInit() {
  }

  closeEvent() {
    this.actionName.emit("closeEvent")
  }

  FirstButton(value) { // Yes
    this.actionName.emit(value);
  }

  SecondButton(value) {  // close button (No)
    this.actionName.emit(value);
  }
}
