import { Injectable } from '@angular/core';
import { Http, Response, HttpModule, RequestOptions, Headers } from '@angular/http';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';



import { environment } from '../../../../environments/environment';
import {HttpCoreService} from '../../../services/http-core.service';
import {Component, Input} from '@angular/core';
import {LocalStorageService} from 'angular-2-local-storage';

@Injectable()
export class MassMenuItemDeleteService {

	menuSelectedItems = [];
	eid = '';
	marketId = '';
	token= '';
	filteredList: any[];
	restScrollcount: any[] = [];
	eq: any;
	userParam: any;
	selectedClasses = '' ;
	filteredStartCount: number = 0;
  	originalStartCount: number = 0;
  	
	constructor(
		public localStorageService: LocalStorageService, 
  		public httpCoreService: HttpCoreService
  	){}

	public getMenuItemList(apiService, data): Observable<any> {
		console.log("getMenuItemListService Called"); 
		return this.httpCoreService.getHttpUrl(apiService, data, 'post');
	}
  	
  	public getLoadLookup(apiService, data): Observable<any> {
    	return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  	}
  	
  	public getAutoSuggestMI(apiService, data): Observable<any> {
    	return this.httpCoreService.getHttpUrl(apiService, data, 'post');
  	}

  	errorHandler(error: any): void {
		console.log(error);
	}
public getClassDropdownData(data) {
    if (data && data.length) {
      const tempArray = [];
      // const length = data.length;
      data.forEach(item => {
        const tempObject = {
          id : '',
          itemName : ''
        };
        tempObject.id = item.entryCode;
        tempObject.itemName = item.translatedValue;
        tempArray.push(tempObject);
      });
      return tempArray;
    }
  }
  
    public getMenuItemClass(data) {
    this.selectedClasses = '';
    if (data && data.length) {
      const length = data.length;
      for (let i = 0; i < length; i++) {
      	if (i == 0){
      		this.selectedClasses = (data[i].id);
      	}else{
      		this.selectedClasses = this.selectedClasses + ',' + (data[i].id);
      	}
      }
      return this.selectedClasses;
    }
  }
}
