export const PTA_CONST = {
    /**
   *
   * Left Selection Panel Constants
   *
   */

    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    STEPPER_PRICE_VIEW: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    MINUS_ONE: -1,
    ENTER_KEY: 13,
    PTA_ACTION_ITEM: 1,
    NUMBER_OF_MONTHS_DISPLAY: 2,
    MENU_RECORD_LIMIT: 10000,
    AUTO_SUGG_LIST_LIMIT: 5,

    TRUE: true,
    FALSE: false,
    NULL: null,
    UNDEFINED: undefined,

    /* popup button constants */
    NO_BUTTON: 'No',
    OK_BUTTTON: 'OK',
    YES_BUTTON: 'Yes',
    NO_BTN_COLOR: 'success',

    STR_ONE: '1',
    STR_TWO: '2',
    STR_THREE: '3',
    EMPTY_STRING: '',
    EMPTY_SPACE: ' ',
    HYPHON: '-',
    FSLASH: '/',
    ID: 'id',
    CONTAINS_FLG: 'c',
    EXACT_FLAG: 'e',
    PTA: 'pta',
    TAX_SM: 'Tax',
    TAX: 'TAX',
    PRICE: 'PRICE',
    REST: 'rest',
    NONE: 'None',
    ARROW: 'arrows',
    VISIBLE: 'visible',
    FINISH: 'finish',
    CROSS: 'cross',
    MENU_HEADER: 'Menu Items',
    PRICE_ACTIVATE: 'PRICE_ACTIVATE',
    STATUS: 'status',
    STATUS_NA: 'statusNa',
    LEFT_REST: 'REST',
    LEFT_MENU: 'MENU',
    MENU: 'MenuItem',
    PRICE_SM: 'Price',
    NAVIGATION: 'arrows',
    OUTSIDE_DAYS: 'visible',
    MSG_MAP_KEY: 'messageMap',
    TIME_ZONE_KEY: 'timeZone',
    EFF_DATE_KEY: 'effectiveDate',
    DATE_FORMAT_KEY: 'selectedDateFormat',
    YYYY_MM_DD: 'YYYY-MM-DD',
    TABLE_VIEW: 'R',
    TREE_VIEW: 'notR',
    REST_VIEW_KEY: 'isRest',
    USER_DETAILs_KEY: 'userDetails',
    USER_ID_KEY: 'userId',
    USER_EID_KEY: 'userEid',
    MARKET_ID_KEY: 'marketId',
    LOOKUP_KEY: 'getlookUps',
    MENU_PERM_KEY: 'REFRESH_MENU_ITEM_SEARCH',
    PROD_CODE: 'productCode',
    PROD_CLASS: 'PRODUCT CLASS',
    DIFF_ALL: 'differentAll',
    APPR_STATUS: 'approvalStatus',
    INVALID_DATE: 'Invalid date',
    PERMISSION_MAP: 'permissionMap',
    EAT_TAKE_MATCHED: 'eatInTakeOutMatched',
    MATCHED: 'Matched',
    MATCHED_ALL: 'matchedAll',
    NOT_MATCHED: 'NotMatched',
    NOT_APPROVED: 'Not Approved',
    NOTAPPROVED: 'NotApproved',
    EAT_BOARD_PRICE: 'eatInBoardPrice',
    EAT_RFM_PRICE: 'eatInRFMPrice',
    EAT_REF_PRICE: 'eatInReferencePrice',
    TAKE_RFM_PRICE: 'takeOutRFMPrice',
    TAKE_REF_PRICE: 'takeOutReferencePrice',
    DEL_BOARD_PRICE: 'deliveryBoardPrice',
    DEL_RFM_PRICE: 'deliveryRFMPrice',
    DEL_REF_PRICE: 'deliveryReferencePrice',
    TAKE_BOARD_PRICE: 'takeOutBoardPrice',
    ACTION_ITEM_ID: 'actionItemId',
    TOTAL_RECD: 'totalRecords',
    PROD_CLASS_NA: 'productClassNa',
    REST_FIELD: 'Restaurant',
    SEC_MENU_VAL: 'secundaryMenuItemValue',

    A_EAT_IN_BOARD_PRICE: 'aeatInBoardPrice',
    A_TAKE_OUT_BOARD_PRICE: 'atakeOutBoardPrice',
    A_DELIVERY_BOARD_PRICE: 'adeliveryBoardPrice',
    A: 'a',
    EAT_IN_TAX_RULE_DEF_VALUE: 'eatinTaxRuleDefVal',
    TAKE_OUT_TAX_RULE_DEF_VALUE: 'takeoutTaxRuleDefVal',
    DELIVERY_TAX_RULE_DEF_VALUE: 'deliveryTaxRuleDefVal',
    EAT_IN_TAX_ENTRY_DEF_VALUE: 'eatinTaxEntryDefVal',
    TAKE_OUT_TAX_ENTRY_DEF_VALUE: 'takeoutTaxEntryDefVal',
    DELIVERY_TAX_ENTRY_DEF_VALUE: 'deliveryTaxEntryDefVal',
    EAT_IN_KEY: 'eatin',
    TAKE_OUT: 'takeout',
    DELIVERY: 'delivery',
    ORIGINAL: 'Original',

    /* URL Constants */
    EAT_IN_TAKE_OUT: 'Eat In/Take Out',
    EAT_IN: 'Eat In',
    OWNER_OPTR_HOME_URL: '/owner-operator-homepage',
    PTA_TAX_URL: '/pta/tax',
    PRICE_URL: 'pta/price',
    PTA_MENU_URL: '/pta/menu-item',
    EFF_DT_URL: 'pta/effective-date',
    REST_TABLE_URL: 'pta/select-restaurants',
    REST_TREE_URL: 'pta/select-restaurant-tree',
    CONFIRM_PRICE_URL: '/pta/confirm-price',
    MII_REST_URL: 'menu-item-inactivation/select-restaurants',
    MII_DATE_URL: 'menu-item-inactivation/effective-date',
    MII_MENU_URL: '/menu-item-inactivation/menu-item',
    MI_INACTIVATION_URL: 'menu-item-inactivation/inactivation',
    MII_REST_TREE_URL: 'menu-item-inactivation/select-restaurant-tree',
    ACTION_ITEM_CREATE_URL: '/action-item/create',
    ACTION_ITEM_EDIT_URL: '/action-item/edit?isEdit=true',

    /* API constants */
    VARIES: 'VARIES',
    PRICE_VALIDATION_API: 'getPriceRulesValidation',
    PRICE_API: 'getPricesForEachRestaurantNew',
    PTA_DATA_UPDATE_API: 'setPTADataUpdated',
    GET_DIM_BY_MENU_API: 'getDimensionsByMenuItem',
    MI_RULE_API: 'activeMIBasedRules',
    RFM_PRICE_API: 'getGlobalParam',

    REST_NODE_LIST_KEY: 'restNodeList',
    MENU_API_NAME: 'getMenuItemList',
    ANCHOR_MENU_API: 'getAnchorMenuItems',
    REST_API_NAME: 'getRestaurantListByUser',
    MENU_AUTO_SUGG_API: 'getMenuItemListAutoSuggestion',
    RESNU: 'resNu',

    NAV_DATE: 'DATE',
    NAV_REST: 'REST',
    NAV_MENU: 'MENU',

    DATE_HEADING: 'Effective Date',
    REST_HEADING: 'Restaurants',
    EFF_DT: 'EFFECTIVE_DATE',
    REST_STPR: 'RESTAURANT',
    MENU_STPR: 'MENU_ITEM',

    DEFAULT: 'Default',
    APPROVED: 'Approved',
    MENU_LIST_PARAM_KEY: 'getValueFromGlobalParam',
    MARKET_DATE_KEY: 'getCurrentDateFromUser',
    EMP_DT_ERR_MSG: 'Date should not be empty',
    REST_CHKBOX_ID: 'restSelectionCheckbox1',
    POP_REST_CHANGE_HEADER: 'Price Tax Activate Warning',
    POP_REST_CHANGE_BODY: 'If you change restaurant selections, all pricing information will be lost.',
    PRICE_LESS_EQUAL_TEXT: 'Please enter price less than or equal to ',
    NOT_NEGATIVE_VALUE:'Value can not be negative.',

    INACTIVATION_SM: 'Inactivation',
    INACTIVATION: 'INACTIVATION',
    ALL: 'All',
    VIEW_OPTIONS: 'View Options',
    NAVER: 'Never',
    TAX_CODE: 'taxCode',
    TAX_RULE: 'taxRule',
    TAX_ENTRY: 'taxEntry',
    ALWAYS: 'Always',
    OPTIONAL: 'Optional',
    DIM_KEY: 'dim',
    COM_KEY: 'com',
    ANC_KEY: 'anc',
    EAT: 'eat',
    /* PDF CSV constants */
    TYPE: 'type',
    DOT_PDF: '.PDF',
    SND_TXT: 'sendText',
    EM_PTA_REP: 'emailPTAReport',
    SM_PDF: 'pdf',
    PDF: 'PDF',
    CSV: 'CSV',
    EMAIL: 'Email',
    API_DATE_FORMAT_MMDDYYY: 'MM/DD/YYYY',
    TIME_STAMP_FORMT_HHmmss: 'HHmmss',
    API_TIME_FORMAT: 'HH:mm',

    /* Tax constants */
    CLOSE_EVENT: 'closeEvent',
    TAX_API_URL: 'getTaxData',
    OK: 'Ok',
    MENU_ITEM_LEFT_PAN_PAGESIZE: 500,
    MENU_ITEM_PAGENO: 1,

    MENU_NUMBER: 'menuItemId',
    MENU_NAME: 'menuItemDs',
    BLANK: 'Blank',
    PARTIAL: 'Partial',
    ANC_STATUS: 'ancstatus',
    DIM_STATUS: 'dimstatus',
    COM_STATUS: 'comstatus',
    RADIO_TEXT: 'radio',


    /*Permission PTA*/
    PERMISSION_OWNER_OPERATOR_PTA_EFFECTIVATE_DATE: 'REFRESH_OWNER_OPERATOR_PTA_EFFECTIVE_DATE',
    PERMISSION_OWNER_OPERATOR_PTA_PRICES: 'REFRESH_OWNER_OPERATOR_PTA_PRICES',
    PERMISSION_OWNER_OPERATOR_PTA_ACTIVATE: 'REFRESH_OWNER_OPERATOR_PTA_ACTIVATE',
    PERMISSION_OWNER_OPERATOR_PTA_TAX: 'REFRESH_OWNER_OPERATOR_PTA_TAX',

    /*Analytics Constant*/
    pageName: "Price Tax Activate",
    //RFM-21258
    DISPLAY_RFM_PRICE_ON_PTA_PARAM_FUNC_ID: 4002,
    DISPLAY_RFM_PRICE_ON_PTA_PARAM_NAME: 'DISPLAY_RFM_PRICE_ON_PTA',

    DISPLAY_ALERT_ON_UPDATING_MBP_PARAM: 'DISPLAY_ALERT_ON_UPDATING_MENU_PRICE_BOARD'
}
