import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PackageScheduleSearchViewComponent } from './package-schedule-search-view/package-schedule-search-view.component';  
import { NewPackageScheduleComponent } from './new-package-schedule/new-package-schedule.component';
@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'list',
        loadChildren: './package-schedule-search-view/package-schedule-search-view.module#PackageScheduleSearchViewModule' 
      },
      {
        path: 'create',
        loadChildren: './new-package-schedule/new-package-schedule.module#NewPackageScheduleModule'
      } 
    ])
  ],
  providers: []
})
export class PackageModule { }
