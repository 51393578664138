import { Inject, Injectable, Output, EventEmitter } from '@angular/core';
import { Http, Response, HttpModule, Headers, RequestOptions } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpCoreService } from '../../../services/http-core.service';
import * as moment from 'moment';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class MiiInactivationService {
  fromHeader = new BehaviorSubject(false);
  clickFromHeader = this.fromHeader.asObservable();

  constructor(private activatedRoute: ActivatedRoute,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService, private http: HttpClient, private httpCoreService: HttpCoreService, private localStorageService: LocalStorageService) {
  }

  getmiiConfirmationData(): Observable<any> {
    return this.http.get('../assets/mock-json/mock-json/mii-inactivation-confirmation.json');
  }

  getmiiInactivationData(): Observable<any> {
    return this.http.get('../assets/mock-json/mock-json/mii-inactivation.json');
  }

  cancelConfirmFromHeader() {
    this.fromHeader.next(true);
  }
}
