import {Inject, Injectable} from '@angular/core';
import {Http, Response, HttpModule, Headers, RequestOptions} from '@angular/http';
import {HttpClientModule, HttpClient } from '@angular/common/http';
import {LocalStorageService} from 'angular-2-local-storage';
import {Observable} from 'rxjs';


import { HttpCoreService } from '../services/http-core.service';

import { Router, Route, ActivatedRoute } from '@angular/router';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class AuthUserService {

	token: string = '';  
	loginRequest:any = {
		"userEid": "",
		"marketId":"",
		"selectedLanguage":""
	};
	urlFromOnline: any;


	constructor(
		private activatedRoute: ActivatedRoute,
		@Inject(SESSION_STORAGE) private sessionStorage: StorageService,
		private httpCoreService: HttpCoreService, private localStorageService: LocalStorageService) {
			
			
			if (this.sessionStorage.get('isSessionStorage') == "false" || this.sessionStorage.get('isSessionStorage') == false) {
			
				if (this.localStorageService.get("userEid") != '' && this.localStorageService.get("userEid") != null && this.localStorageService.get("userEid") != undefined) {
					this.loginRequest.userEid = this.localStorageService.get("userEid");
					this.loginRequest.marketId = this.localStorageService.get("marketId");
					this.loginRequest.selectedLanguage = this.localStorageService.get("selectedLanguage");
				} else {
				 	this.urlFromOnline = window.location.pathname.split(';');
					/*
					for (let i = 1; i < this.urlFromOnline.length; i++){
						console.log(this.urlFromOnline[i]);
						let urlParam=this.urlFromOnline[i].split('=');
						console.log(urlParam);
						console.log(urlParam[0]);
						console.log(urlParam[1]);
						this.localStorageService.set(urlParam[0],urlParam[1]); 		
						console.log(this.localStorageService.get(urlParam[0]));
					}
					*/
					
					
					this.loginRequest.userEid = this.localStorageService.get("userEid");
					this.loginRequest.marketId = this.localStorageService.get("marketId");
					this.loginRequest.selectedLanguage = this.localStorageService.get("selectedLanguage");
				}
			}		
		} 
	
	public getAuthUserDetails(apiService): Observable<any> {
		console.log("beforeEach AuthUserService getAuthUserDetails");
		if (this.sessionStorage.get('isSessionStorage') == "true" || this.sessionStorage.get('isSessionStorage') == true) {
		
			this.loginRequest.userEid = this.sessionStorage.get("userEid");
			this.loginRequest.marketId = this.sessionStorage.get("marketId");
			this.loginRequest.selectedLanguage = this.sessionStorage.get("selectedLanguage");
			this.loginRequest.userToken = this.sessionStorage.get("userToken");
			this.loginRequest.isRest = this.sessionStorage.get("isRest");
			
		} else {
			this.loginRequest.userEid = this.localStorageService.get("userEid");
			this.loginRequest.marketId = this.localStorageService.get("marketId");
			this.loginRequest.selectedLanguage = this.localStorageService.get("selectedLanguage");
		}
				
		return this.httpCoreService.getHttpUrl(apiService, this.loginRequest, 'post');
	}
		  
	public getOnlineHelpData(apiService,data): Observable<any>{
		return this.httpCoreService.getHttpUrl(apiService, data, 'get');
	}
	errorHandler(error: any): void {
		console.log(error);
	}

	public getAuthToken(): string {
		let accessToken = ''
		accessToken = this.localStorageService.get('accessToken')
		if(!accessToken || accessToken == null || accessToken == 'null' || accessToken == '') {
		accessToken = sessionStorage.getItem('accessToken')
		}

		if(accessToken.startsWith('"') && accessToken.endsWith('"')) {
		accessToken = accessToken.substr(1, accessToken.length - 2)
		}

		return accessToken;
	}

}