import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../layout/shared-modules/shared.module';

import { FormsModule } from '@angular/forms';

import { RestSelectionComponent } from './restselection/masspromotionupdate.restselection.component';
import { PromoSelectionComponent } from './promoselection/masspromotionupdate.promoselection.component';
import { MassPromotionUpdateComponent } from './masspromotionupdate.component';
import { MassPromotionUpdateBusinessService } from './services/masspromotionupdate-business.service';
import { MassPromotionUpdateHttpService } from './services/masspromotionupdate-http.service';
import { MassPromotionUpdateModel } from './model/masspromotionupdate.model';
import { MassPromotionUpdateAndReviewComponent } from './updateandreview/masspromotionupdate.updateandreview.component';
import { MassPromotionResultsComponent } from './results/masspromotionupdate.results.component';
import { MassPromoPipe } from './utilities/mass-promo.pipe';
import { MassPromoResultsPipe } from './utilities/mass-promo-results.pipe';
import { MassPromoUpdatePipe } from './utilities/mass-promo-update.pipe';
import { MassPromotionUpdateTreeGenSelectionComponent } from './treegenselection/masspromotionupdate.treegenselection.component';
import { MassRestSelectionPipe } from './utilities/mass-promo-restSelection.pipe';
import { PackageScheduleService } from '../package/services/package-schedule.service';

import { DataFormatPromoFilterPipe } from './utilities/promo-data-format-filter.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { SelectedLangService } from '../../services/selected-lang.service';
import { GenerateAuditBusinessService } from '../audit-log/generate-audit-log/services/generate-audit-business.service';
import { MatInputModule, MatAutocompleteModule } from '@angular/material';
import { TreePageModule } from '../package/app-page-tree/app-page-tree.module';
import { TreePageComponent } from '../package/app-page-tree/app-page-tree.component';

// routes
export const ROUTES: Routes = [
  { path: '', component: MassPromotionUpdateComponent }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    SharedModule,
    MatAutocompleteModule,
    FormsModule,
    MatInputModule,
    TreePageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    MassPromotionUpdateComponent,
    RestSelectionComponent,
    PromoSelectionComponent,
    MassPromoPipe,
    MassPromoResultsPipe,
    MassPromoUpdatePipe,
    MassPromotionUpdateAndReviewComponent,
    MassPromotionResultsComponent,
    MassPromotionUpdateTreeGenSelectionComponent,
    MassRestSelectionPipe,
    DataFormatPromoFilterPipe
  ],
  providers: [
    MassPromotionUpdateBusinessService,
    MassPromotionUpdateHttpService,
    MassPromotionUpdateModel,
    SelectedLangService,
    MassPromoPipe,
    MassRestSelectionPipe,
    MassPromoResultsPipe,
    MassPromoUpdatePipe,
    GenerateAuditBusinessService,
    PackageScheduleService,
    TreePageComponent,
  ],
  exports: [MassPromotionUpdateComponent, TreePageComponent],
})
export class MassPromotionUpdateModule { }
