import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService } from 'angular-2-local-storage';

import { UserBean } from '../../../models/userbean';
import { MiRequestDetailBean } from '../../../models/miRequestDetailBean';
import { MiListRequestVO } from '../../../models/miListRequestVO';
import { DropdownService } from '../../../services/dropdown.service';
import { appconstant } from '../../../../environments/app.constant';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MassMenuItemUpdateHttpService } from '../../../components/mass-menu-item-update/services/mass-menu-item-update-http.service';
import { MassMenuItemUpdateModel } from '../../../components/mass-menu-item-update/model/mass-menu-item-update-model';
import { GenerateAuditBusinessService } from '../../../components/audit-log/generate-audit-log/services/generate-audit-business.service';
import { filter, cloneDeep } from 'lodash';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';
import { MiFilterSharedPipe } from '../../../shared/pipes/menu-item-filter.pipe';
import { User } from '../../../models/user';
import { RfmApplicationSettingService } from '../../../services/rfm-application-setting.service';

declare var $: any;
@Component({
	selector: 'app-online-selection',
	templateUrl: './selection.component.html',
	styleUrls: ['./selection.component.css'],
	providers: [MiFilterSharedPipe]
})

@Injectable()
export class SelectionComponent implements OnInit {
	filterClassList = [];
	miListSuggest = [];
	existAnyCheckedVar: boolean;

	isMIVisible: boolean;
	userDetails: User;
	permissions: any;
	modelIdCancel = '';
	errorMsg: any;
	miListRequestVO: MiListRequestVO = new MiListRequestVO(new UserBean(), new MiRequestDetailBean());
	miList: any = [];
	duplicateMenuList: any[];
	exact = false;
	showTable = false;
	noRecords = false;
	reverse = false;
	slctAllMenusHeader = false;
	lookupList: any = [];
	prodClassSearch: any[] = [];
	layeringLogicType;
	msg;
	dropDownData = [];
	prodClassListSettings = {};
	classList = [];
	miCodeFilter = '';
	miNameFilter = '';
	miClassFilter = [];
	miStatusFilter = [];
	approvalStatusFilter = [];
	sortColumn = '';
	ascending = {
			productCode: false,
			productDescription: false,
			prodClass: false,
			status: false,
			approvalStatus: false
	};
	menuItemDropDownListStatus = [];
	menuselectedItems = [];
	menuAprvlStsItem = [];
	menuItemDropDownListFilterStatus = [];
	aprvlStatusList = [];
	statusClassListSettings = {};
	statusResultTableSettings = {};
	constants = appconstant;
	miUpdateType = [];
	autoSuggestThreshold: number = 3;

	contentSelected: string = 'btn btn-sm btn-success btn-responsive btnshape';
	exactSelected: string = 'btn btn-sm  btn-responsive btnshape';
	@Input()
	ptaReportFlag:boolean = true;
	searchFilterText = '';

	constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService,
			private localStorageService: LocalStorageService,
			public miFilterPipe: MiFilterSharedPipe,
			public massMenuItemUpdateModel: MassMenuItemUpdateModel,
			public dropdownService: DropdownService,
			private generateAuditBusinessService: GenerateAuditBusinessService,
			private massMenuItemUpdateHttpService: MassMenuItemUpdateHttpService,
			private spinnerService: Ng4LoadingSpinnerService,
			private rfmApplicationSettingService: RfmApplicationSettingService,
	) {
		this.isMIVisible = true;				
	}
	onlineSelectionModel = this.massMenuItemUpdateModel.online.selection;

	ngOnInit() {	
		this.menuItemDropDownListStatus = appconstant.STATUS_MENU_DROPDOWN_OPTIONS;
		this.menuselectedItems.push(appconstant.STATUS_MENU_DROPDOWN_OPTIONS[0]);
		this.getMiUpdateType();
		this.retrievePermissions();
		this.statusClassListSettings = this.dropdownService.dropDownSingleSettings;
		this.statusResultTableSettings = this.dropdownService.dropDownResultTableSettings;
		this.miListRequestVO.userBean.userEid = this.sessionStorage.get('userEid');
		this.miListRequestVO.userBean.marketId = this.sessionStorage.get('marketId');
		this.layeringLogicType = this.sessionStorage.get('layeringLogicType');
		this.miListRequestVO.miRequestBean.approvalStatus = '';
		this.miListRequestVO.miRequestBean.status = '';
		this.noRecords = false;
		this.commonSearchAction();
		this.loadLookup();
		this.getSelectFlag('c');
		this.initializeDropdowns();	
		
		this.rfmApplicationSettingService.get(this.miListRequestVO.userBean.marketId, 0, appconstant.NO_CHAR_AUTO_SUGGESTION).subscribe(result => {
			this.autoSuggestThreshold = parseInt(result.value);
		  })	
	}

	retrievePermissions() {
		this.userDetails = this.sessionStorage.get('userDetails');
		if (this.userDetails && this.userDetails['permissionMap']) {
			this.permissions = this.userDetails['permissionMap'];
		}
	}

	initializeDropdowns() {
		this.prodClassListSettings = this.dropdownService.dropDownSettings;
	}
	searchMenu() {
		if(this.searchFilterText.indexOf('-') == -1)
		{
			this.miListRequestVO.miRequestBean.filterText = this.searchFilterText;
		}
		
		this.massMenuItemUpdateModel.online.updateAndReview.disableCancel = false;
		let miSts: any;
	if (this.prodClassSearch != null) {
		this.miListRequestVO.miRequestBean.productClass = this.generateAuditBusinessService.getMenuItemClass(this.prodClassSearch);
	}
	if (this.layeringLogicType == 2) {
		this.miListRequestVO.miRequestBean.status = this.menuselectedItems[0].id;
		miSts = 'status';
	} else {
		this.miListRequestVO.miRequestBean.approvalStatus =
			this.menuAprvlStsItem[0] && this.menuAprvlStsItem[0].id ? this.menuAprvlStsItem[0].id : '';
			miSts = 'approvalStatus';
	}
	this.miListRequestVO.miRequestBean.orderBy = '1';
	this.miListRequestVO.miRequestBean.sortOrder = 'ASC';

	this.spinnerService.show();
	this.massMenuItemUpdateHttpService.getMenuItemList('listMasterMiStatus', this.miListRequestVO)
	.subscribe((menuItemList) => {
		this.miList = menuItemList.listMenuitem;
		this.massMenuItemUpdateModel.online.updateAndReview.showUpdateTable = false;
		if (menuItemList.resultLimited == true) {
			this.msg = 'Please redefine the search criteria. Too many results to display.';
			this.noRecords = true;
			this.showTable = true;
			this.massMenuItemUpdateModel.online.results.table = [];
			this.massMenuItemUpdateModel.online.results.filterNumber = [];
        	this.massMenuItemUpdateModel.online.results.filterName = [];
       		this.massMenuItemUpdateModel.online.results.filterLanguage = [];
        	this.massMenuItemUpdateModel.online.results.filterResults = [];
        	this.massMenuItemUpdateModel.online.results.filterMessage = [];
		} else {
			this.generateAuditBusinessService.layeringLogicType = this.layeringLogicType;
			this.onlineSelectionModel.miList = menuItemList.listMenuitem;
			this.showTable = true;
			this.spinnerService.hide();
			if (this.onlineSelectionModel.miList == null || !this.onlineSelectionModel.miList.length) {
				this.msg = '0 records found for the search criteria.';
				this.noRecords = true;
				this.massMenuItemUpdateModel.online.results.table = [];
				this.massMenuItemUpdateModel.online.results.filterNumber = [];
        		this.massMenuItemUpdateModel.online.results.filterName = [];
       			this.massMenuItemUpdateModel.online.results.filterLanguage = [];
        		this.massMenuItemUpdateModel.online.results.filterResults = [];
        		this.massMenuItemUpdateModel.online.results.filterMessage = [];
			} else {
				this.noRecords = false;
				this.massMenuItemUpdateModel.online.results.table = [];
				this.massMenuItemUpdateModel.online.results.filterNumber = [];
        		this.massMenuItemUpdateModel.online.results.filterName = [];
       			this.massMenuItemUpdateModel.online.results.filterLanguage = [];
        		this.massMenuItemUpdateModel.online.results.filterResults = [];
        		this.massMenuItemUpdateModel.online.results.filterMessage = [];
				this.filterClassList = _.sortBy(this.generateAuditBusinessService.getFltrClassDropdownData(
						this.generateAuditBusinessService.getUniqueData(this.onlineSelectionModel.miList, 'prodClassId')),
				'itemName');

				this.menuItemDropDownListFilterStatus = _.sortBy(this.generateAuditBusinessService.getFltrSts(
						this.generateAuditBusinessService.getUniqueData(this.onlineSelectionModel.miList, miSts),
						miSts, 'menu'), 'itemName');

				this.duplicateMenuList = _.cloneDeep(this.miList);
				this.sort('productCode');
			}
		}

	}, err => this.errorMsg = <any>err);
	this.commonSearchAction();
	}

	commonSearchAction() {
		this.miCodeFilter = '';
		this.miNameFilter = '';
		this.miClassFilter = [];
		this.miStatusFilter = [];
		this.approvalStatusFilter = [];
		this.slctAllMenusHeader = false;
		this.ascending = {
				productCode: false,
				productDescription: false,
				prodClass: false,
				status: false,
				approvalStatus: false
		};
	}

	/*
	 * Function to sort the Menu Item list. @param : key - column name.
	 */
	sort(key: string) {
		this.sortColumn = key;
		this.onlineSelectionModel.miList.sort((a, b) => {
			if (key =='productCode'){				
					return a[key] > b[key]?1:-1;				
			}else{				
					return a[key].toLowerCase() > b[key].toLowerCase()?1:-1;				
			}			

		});
		if (this.ascending[key]) {
			this.onlineSelectionModel.miList.reverse();
		}
		this.ascending[key] = !this.ascending[key];
	}

	/*
	 * Function to check / uncheck all checkboxes, triggered on 'select all'
	 * click. @param : checked - true/ false.
	 */
	selectAllMenus(selected: boolean) {
		const length = this.onlineSelectionModel.miList.length;
		let mnIds:any[] =[]
		if(selected == true){
			this.existAnyCheckedVar = true;
		}else{
			this.existAnyCheckedVar = false;
		}       
		for (let i = 0; i < length; i++) {
			this.onlineSelectionModel.miList[i].selected = selected;			
			if (selected) {
				mnIds.push(this.onlineSelectionModel.miList[i])
			this.sessionStorage.set('menuItemsAccess' ,mnIds);
			} else {
				this.sessionStorage.set('menuItemsAccess' ,[]);
			}			      
		}    
		this.onlineSelectionModel.formDirty = true;    
	} 

	filterMenu() {
		
		if (this.onlineSelectionModel.miList.length === this.duplicateMenuList.length) {
			this.duplicateMenuList = _.cloneDeep(this.onlineSelectionModel.miList);
		}
		this.onlineSelectionModel.miList = this.miFilterPipe.transform(this.duplicateMenuList, this.miCodeFilter,
				this.miNameFilter, this.miClassFilter, this.miStatusFilter, this.approvalStatusFilter);

		if(this.miClassFilter.length > 0 &&  this.approvalStatusFilter.length > 0 && this.miClassFilter && this.approvalStatusFilter){
			this.onlineSelectionModel.miList = this.miFilterPipe.transformMiApprovalStatus(this.onlineSelectionModel.miList, this.approvalStatusFilter);
		}

		if(this.miClassFilter.length > 0 && this.miStatusFilter.length > 0 && this.miClassFilter && this.miStatusFilter){
			this.onlineSelectionModel.miList = this.miFilterPipe.transformMItemStatus(this.onlineSelectionModel.miList, this.miStatusFilter);
		}

	}

	toggleCheckBox(menu) {
		menu.selected = !menu.selected;
		this.verifyAllCheckbox();
	}
	toggleHeader(checked) {
		
		if (!checked) {
			this.slctAllMenusHeader = false;        
		} else {
			this.slctAllMenusHeader = this.onlineSelectionModel.miList
			.every(menu => menu.selected);  
		 

		}
		this.onlineSelectionModel.formDirty = true; 
		this.verifyAllCheckbox();
	}

	verifyAllCheckbox(){		
		const length = this.onlineSelectionModel.miList.length;
		let testid:any[] =[];
		for (let i = 0; i < this.onlineSelectionModel.miList.length; i++) {
			let element =this.onlineSelectionModel.miList[i]
		if (element.selected ==  true) {
			testid.push(this.onlineSelectionModel.miList[i])
			this.sessionStorage.set('menuItemsAccess' ,testid);	
			
		}
				
			
		}
	
		for (let i = 0; i < length; i++) {
			
			if(this.onlineSelectionModel.miList[i].selected == true){
				
				this.existAnyCheckedVar = true;

				break;
			}else{
				this.existAnyCheckedVar = false;
				testid =[]
			}

		}		
	}

	keyDownFunction(event) {
		this.miListRequestVO.miRequestBean.filterText = this.searchFilterText;
		if (event.keyCode == 13) {
			this.searchMenu();
		}
	}

	/* Select Contains/Exact Flag */
	getSelectFlag(flag) {
		if (flag == 'c') {
			this.contentSelected = 'btn btn-sm btn-responsive btnshape btn-success btn-contain';
			this.exactSelected = 'btn btn-sm  btn-responsive btnshape';
			this.miListRequestVO.miRequestBean.filterType = 'CONTAINS';
		} else if (flag == 'e') {
			this.contentSelected = 'btn btn-sm  btn-responsive btnshape';
			this.exactSelected = 'btn btn-sm btn-responsive btnshape  btn-success btn-contain';
			this.miListRequestVO.miRequestBean.filterType = 'EXACT';
		}
	}

	loadLookup() {
		this.lookupList = [];
		this.massMenuItemUpdateHttpService.getLoadLookup('loadLookup', this.miListRequestVO.userBean)
		.subscribe((lookupList) => {
			this.lookupList = lookupList;
			this.classList = this.generateAuditBusinessService.getClassDropdownData(this.lookupList.productClassList);
			this.aprvlStatusList = this.generateAuditBusinessService.getClassDropdownData(this.lookupList.approvalStatusList);
			this.menuAprvlStsItem.push(this.aprvlStatusList[0]);
		}, err => this.errorMsg = <any>err);
	}

	getAutoSuggestMI(searchText) {
		this.miListSuggest = [];
		if (searchText.length === 0) {
			this.miListSuggest = [];
		}
		if (searchText.length < this.autoSuggestThreshold) {
			return;
		}
		this.massMenuItemUpdateHttpService.getAutoSuggestMI('getAutoFillMenuItems', searchText)
		.subscribe((menuItemList) => {
			this.miListSuggest = menuItemList;
		}, err => this.errorMsg = <any>err);
	}

	getMiUpdateType() {
		this.massMenuItemUpdateHttpService.getMiUpdateType('getMiUpdateType')
		.subscribe((menuItemDatat) => {
			if (menuItemDatat.miUpdateType != null && menuItemDatat.miUpdateType.length > 0){
				for (let i = 0; i < menuItemDatat.miUpdateType.length; i++) {
					if (menuItemDatat.miUpdateType[i].translatedValue != null && menuItemDatat.miUpdateType[i].translatedValue != undefined){
						this.miUpdateType.push(menuItemDatat.miUpdateType[i]);
						this.massMenuItemUpdateModel.online.selection.miUpdateType= 1;
					}
				}
			}else{
				this.miUpdateType.push(appconstant.MI_UPDATE_TYPE[0]);
			}
		}, err => this.errorMsg = <any>err);
	}
	
	updateSelectedItems() {
		this.massMenuItemUpdateModel.online.updateAndReview.disableCancel = false;
		this.massMenuItemUpdateModel.online.updateAndReview.disableTableHeaderInput = false;
		const selectedItems = filter(this.massMenuItemUpdateModel.online.selection.miList, { 'selected': true as any});
		if (selectedItems.length) {
			this.spinnerService.show();
			this.massMenuItemUpdateHttpService.getUpdateAndReviewData('listMenuItemMassData')
			.subscribe((menuItemDatat) => {
				this.menuselectedItems = [];
				this.menuselectedItems.push(appconstant.STATUS_MENU_DROPDOWN_OPTIONS[0]);
				this.menuAprvlStsItem = [];
				this.menuAprvlStsItem.push(this.aprvlStatusList[0]);
				this.prodClassListSettings = '';
				this.prodClassSearch = [];
				this.massMenuItemUpdateModel.online.updateAndReview.table = menuItemDatat.listMiLangDetail;
				this.massMenuItemUpdateModel.online.updateAndReview.tableCopy = cloneDeep(menuItemDatat.listMiLangDetail);
				this.massMenuItemUpdateModel.online.updateAndReview.allowNameValidation = menuItemDatat.allowNameValidation;
				this.massMenuItemUpdateModel.online.selection.isReadOnly = false;
				this.massMenuItemUpdateModel.online.selection.disableUpdate = true;
				this.miListRequestVO.miRequestBean.filterText = '';
				this.showTable = false;
				this.massMenuItemUpdateModel.online.updateAndReview.showUpdateTable = true;
				this.spinnerService.hide();
				$('#collapseOne').collapse('toggle');
				$('#collapseTwo').collapse('toggle');
			}, err => this.errorMsg = <any>err);      
		}
	}

	resetTable() {
		this.onlineSelectionModel.miList = [];
		this.miListRequestVO.miRequestBean.filterText = '';
		this.searchFilterText ='';
		this.prodClassSearch = [];
		this.menuAprvlStsItem = [];
		this.menuAprvlStsItem.push(this.aprvlStatusList[0]);
		this.menuselectedItems = [];
		this.menuselectedItems.push(appconstant.STATUS_MENU_DROPDOWN_OPTIONS[0]);
		this.showTable = false;
		this.commonSearchAction();
		this.existAnyCheckedVar = false; 
	}

	onClickCancel(){
		if (this.onlineSelectionModel.miList.length > 0 ||
				(this.miListRequestVO.miRequestBean.filterText != "" && this.miListRequestVO.miRequestBean.filterText != undefined) ||
				this.prodClassSearch.length > 0 ||
				this.menuAprvlStsItem[0].id != 2 ||
				this.menuselectedItems[0].id != ""){
			this.modelIdCancel = '#modal-question';
		}

	}

	cancel(cancel){
		if (cancel == 'cancel'){
			this.modelIdCancel = "";
		}else{
			this.resetTable();
			this.modelIdCancel = "";
		}
	}	

	setSelection(miCode){	
		this.getSelectFlag('e');
		 
		let searchText:any = miCode;
		 
		if(searchText.match(/[-]/) != null) {
			searchText = searchText.split('-')[0].trim();
		}
		 
		this.miListRequestVO.miRequestBean.filterText = searchText;
	}
}

