import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxPaginationModule } from '../../utilities/ngx-pagination';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { AngularMultiSelectModule } from '../../utilities/angular2-multiselect-dropdown';
import { NgMultiSelectDropDownModule } from '../../../../node_modules/ng-multiselect-dropdown';
import { FormatDatePipeOne } from '../../shared/pipes/format-date.pipe';
import { SelectionComponent } from './selection/selection.component';
import { MatAutocompleteModule, MatInputModule } from '@angular/material';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ScrollingModule,
    NgxPaginationModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    MatAutocompleteModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: 
  [SelectionComponent],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [FormatDatePipeOne],
  exports: [
    CommonModule,
    FormsModule,
    ScrollingModule,
    NgxPaginationModule,
    AngularMultiSelectModule,
    NgMultiSelectDropDownModule,
    SelectionComponent,
    MatAutocompleteModule,
    MatInputModule,
  ]
})
export class SharedModule { }
