import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpCoreService } from '../../services/http-core.service';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject } from 'rxjs';
import { connection } from '../../../environments/connection.properties';
import { OOP_CONST } from '../constants/rfmOOP.constant';
import { OOP_URL_CONST } from '../constants/rfmOOPUrl.constant';

@Injectable({
  providedIn: 'root'
})

export class OwnerOperatorHeaderService {
  private isChangeMade = new BehaviorSubject<boolean>(false);
  isChangeMadeCast = this.isChangeMade.asObservable();
  permission: Array<any> = [];
  dashboardData: object = {
    "ConfigData": []
  };
  public urlToCall:any;

  constructor(private httpClient: HttpClient, public httpCoreService: HttpCoreService, private http: HttpClient,
    private localStorageService: LocalStorageService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService) { }


  validatePermission(data) {
    this.dashboardData[OOP_CONST.CONFIGDATA] = [];
    this.permission = this.sessionStorage.get(OOP_CONST.PERMISSIONMAP);
    for (var i = 0; i < data.length; i++) {
      var permissionArr = data[i].permission.split(OOP_CONST.SYMBOL_COMMA);
      if (permissionArr.length > 0) {
        var isValid = true;
        for (var j = 0; j < permissionArr.length; j++) {
          if (!(this.permission[permissionArr[j]] == 1 || this.permission[permissionArr[j]] == 2 || this.permission[permissionArr[j]] == 3)) {
            isValid = false;
            break;
          }
        }
        if (isValid) {
          this.dashboardData[OOP_CONST.CONFIGDATA].push(data[i]);
        }
      }
    }
    return this.dashboardData[OOP_CONST.CONFIGDATA];
  }

  isChangeMadeFunction(isChangeMade) {
    this.isChangeMade.next(isChangeMade);
  }

  // public validateLegacyRFMPage() {
  //   let newLegacyFlag: boolean;
  //   console.log("header URL = "+location.pathname);
  //   if (location.pathname == '/message-management' || location.pathname == '/action-item-admin') {
  //     newLegacyFlag = true;
  //   }
  //   else {
  //     newLegacyFlag = false;
  //   }
  //   return newLegacyFlag;
  // }

  // public validateToShowAdminNewLegacy() {
  //   let newLegacyFlag = this.validateLegacyRFMPage();
  //   let isGlobalUser = this.sessionStorage.get('isGlobalUser');
  //   let showToAdminFlag: boolean;
  //   if ((isGlobalUser == 1 || isGlobalUser === true) && newLegacyFlag === true)
  //     showToAdminFlag = true;
  //   else
  //     showToAdminFlag = false;

  //   return showToAdminFlag;
  // }
  public checkSession(apiService, data) {
    return new Promise((resolve) => {
      let random = Math.floor(Math.random() * 1000000);

      let api = apiService + '?randomNumber=' + random + '&token=' + this.sessionStorage.get(OOP_CONST.TOKEN);

      let url = `${window.location.protocol}//${window.location.host}${api}`;
      let headers = new HttpHeaders();

      headers.append('Content-Type', 'application/json; charset=utf-8');
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'text/html, application/xhtml+xml, */*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        responseType: 'text'
      }

      this.http.get(url, { responseType: 'text' }).subscribe(res => {

        resolve(res)
      })
    })

  }
  public OOPlogout() {
    if (connection.RELATIVE_PATH === 'true' || connection.RELATIVE_PATH == 'true') {
      this.urlToCall = location.protocol + OOP_CONST.SYMBOL_SLASH + location.hostname + OOP_CONST.SYMBOL_COLON +
        connection.RELATIVE_API_PORT + connection.RFM_HOME_API_PATH;
    } else {
      this.urlToCall = connection.RFM_HOME_FULL_URL;
    }

    let url = this.urlToCall
      + OOP_URL_CONST.RFM_LOGOUT_ACTION_URL;
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.open(url, OOP_CONST.SELF);
  }
}
