import { Injectable } from '@angular/core'; 
import { Component, Input } from '@angular/core'; 
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';


import {environment} from '../../environments/environment.json';
import { LocalStorageService } from 'angular-2-local-storage';

const httpOptions = {
  headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*' })
};

@Injectable()
export class SessionLiveServiceService {

  envSessionLiveImageUrl: any= environment.sessionLiveImageUrl;
  constructor(public http:Http,private localStorageService: LocalStorageService) {       
  }

  public getSessionLive() {
    var d = new Date();
      console.log("session:",this.envSessionLiveImageUrl+d.getTime());
    let headers = new Headers({ 'Authorization':  `${this.localStorageService.get('accessToken')}`,
    'Access-Control-Allow-Origin': '*' });
     let options = new RequestOptions({ withCredentials: true, headers: headers });

      this.http.get(this.envSessionLiveImageUrl+d.getTime(), options)
      .subscribe((response) => {
         console.log("resp: "+response); 
      });

   }

    

   
}
