import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route,  CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { AuthUserService } from "./auth-user.service";
import { LocalStorageService } from 'angular-2-local-storage';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  
  interval: any;
  currentInstance: any;
  userDetails:any;
  errorMsg: string;
  isUserAuthenticated:boolean;
  redirectUrl :any;

  constructor(private spinnerService: Ng4LoadingSpinnerService, public authUserService:AuthUserService, private router: Router, private localStorageService: LocalStorageService) {
    this.currentInstance = this;
  }
 
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.spinnerService.show();
    this.redirectUrl = state.url;
    return this.checkLoggedIn();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLoggedIn();
  }

  canLoad(route: Route): boolean {
    return this.checkLoggedIn();
  }
  
  checkLoggedIn(): boolean { 
    let loginUserStatus = this.localStorageService.get('loginUserStatus');
    if (loginUserStatus == "true") { 
      this.spinnerService.hide();
      return true;
    } else {
     return false; 
    }   
  }
}
