import { Component, OnInit } from '@angular/core';

//import { of } from 'rxjs';
//import { of } from 'rxjs/observable/of';
//import { of } from 'rxjs';

import {Observable} from 'rxjs';
import * as _ from 'lodash';
import { LocalStorageService } from 'angular-2-local-storage';
import { User } from '../../../models/user';
import { UserBean } from '../../../models/userbean';


import { DropdownService } from '../../../services/dropdown.service';
import { appconstant } from '../../../../environments/app.constant';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MassPromotionUpdateHttpService } from '../services/masspromotionupdate-http.service';

import { MassPromotionUpdateModel } from '../model/masspromotionupdate.model';

import { PackageScheduleService } from '../../package/services/package-schedule.service';

import { filter, map, pick, partialRight, cloneDeep } from 'lodash';
import { MassRestSelectionPipe } from '../utilities/mass-promo-restSelection.pipe';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';
import { HeaderComponent } from '../../header/header.component';
import { PromoSelectionComponent } from '../promoselection/masspromotionupdate.promoselection.component';


declare var $: any;
@Component({
	selector: 'app-restaurant-selection',
	templateUrl: './masspromotionupdate.restselection.component.html',
	styleUrls: ['./masspromotionupdate.restselection.component.css'],
	providers: [PromoSelectionComponent]
})

@Injectable()
export class RestSelectionComponent implements OnInit {
	userDetails: User;
	permissions: any;
	errorMsg: any;
	restaurantInfo: any;
	customRegionFieldOffice: string;
	customFieldCoop: string;
	isUSMarket: boolean;
	isRegionFieldOfficeVisible: boolean = true;
	isCoopVisible: boolean = true;
	regionFieldOfficeStatusFilter = [];
	regionFieldOfficeDropDownListFilter = [];
	regionFieldOfficeResultTableSettings = {};
	coopStatusFilter = [];
	coopDropDownListFilter = [];
	restSelectionResultTableSettings = {};
	existAnyCheckedVar: boolean;
	selectAllRestHeader = false;
	
	sortColumn = '';
	num = 0;
	nodesSelected = [];

	modelIdCancel = '';
	msg = '0 records found for the criteria.';
	
	onlineRestSelectionModel = this.massPromotionUpdateModel.online;
	onlinePromoSelectionModel = this.massPromotionUpdateModel.online.promoSelection;
	restSelectionFilterModel = this.massPromotionUpdateModel.online.restSelection.filter;
	restSelectionSortModel = this.massPromotionUpdateModel.online.restSelection.sortAscending;
	duplicateRestList: any = [];
	
	constructor(@Inject(SESSION_STORAGE) public sessionStorage: StorageService,
			private localStorageService: LocalStorageService,
			private massPromotionUpdateHttpService: MassPromotionUpdateHttpService,
			private massPromotionUpdateModel: MassPromotionUpdateModel,
			private restSelectionFilterPipe : MassRestSelectionPipe,
			public dropdownService: DropdownService,
			private spinnerService: Ng4LoadingSpinnerService,
			private packageScheduleService: PackageScheduleService,
			private headerComponent : HeaderComponent,
			private promoSelectionComponent : PromoSelectionComponent
	) {
	}
	
	restSelectionModel = this.massPromotionUpdateModel.online.restSelection;
	
	ngOnInit() {
		this.restSelectionResultTableSettings = this.dropdownService.dropDownResultTableSettings;
		this.setMarketFlagForUS();
		this.checkCustomRegionFieldOffice();
		this.checkCustomFieldCoop();
		this.retrievePermissions();
		this.getRestInfoListForMPU();
	}
	
	retrievePermissions() {
		this.userDetails = this.sessionStorage.get('userDetails');
		if (this.userDetails && this.userDetails['permissionMap']) {
			this.permissions = this.userDetails['permissionMap'];
		}
	}
	
	keyDownFunction(event) {
		if (event.keyCode == 13) {
			// this.searchMenu();
		}
	}
	getRestInfoListForMPU(){
		console.log("MPU Init Call");
		console.log(this.sessionStorage.get("restNodeList"))
		this.spinnerService.show();
		let restaurantNodeList: string;
		let marketId: string;
		let userId: string;
		let restaurantInputData =  {
				restaurantNodeList,
				marketId,
				userId
		};
		restaurantInputData.restaurantNodeList = this.sessionStorage.get('restNodeList');
		restaurantInputData.marketId = this.sessionStorage.get('marketId');
		restaurantInputData.userId = this.sessionStorage.get('userId');
	
	
	
		this.massPromotionUpdateModel.online.restSelection.restaurantNodeList = restaurantInputData.restaurantNodeList
		this.massPromotionUpdateModel.userBean.marketId = parseInt(restaurantInputData.marketId)
		this.massPromotionUpdateModel.userBean.userEid = this.sessionStorage.get('userEid');
	
	
		let tempList: any = [];
		let tempListCoop: any = [];
		let removedDuplicatedList: any;
		let removedDuplicatedListCoop: any;
		this.massPromotionUpdateHttpService.getRestInfoListForMPU('getRestInfoListForMPU', restaurantInputData)
		.subscribe((results) => {
			this.restaurantInfo = results;
			this.restSelectionModel.restaurantListForMPU = results;
			this.duplicateRestList = this.restSelectionModel.restaurantListForMPU;
	
	
			let i = 0;
			for (let rest of this.restaurantInfo){
				if (this.isUSMarket){
					tempList.push(rest.restNodeHrcy[1].SHRT_NA)
					this.restSelectionModel.restaurantListForMPU[i].regionFieldOffice = rest.restNodeHrcy[1].SHRT_NA
	
				} else {
					tempList.push(rest.restNodeHrcy[0].SHRT_NA)
					this.restSelectionModel.restaurantListForMPU[i].regionFieldOffice = rest.restNodeHrcy[0].SHRT_NA
	
				}
	
	
				if (this.isUSMarket){
					tempListCoop.push(rest.restNodeHrcy[0].SHRT_NA)
					this.restSelectionModel.restaurantListForMPU[i].coop = rest.restNodeHrcy[0].SHRT_NA
	
				}
				i++;
	
			}
	
			removedDuplicatedList = Array.from(new Set(tempList));
			removedDuplicatedList.forEach((item, index) => { const tempObject = {
					id: '',
					itemName: ''
			};
			tempObject.id = index;
			tempObject.itemName = item;
			this.regionFieldOfficeDropDownListFilter.push(tempObject);
			});
	
			if (removedDuplicatedList.length == 1){
				this.isRegionFieldOfficeVisible = false;
			}
	
			if (this.isUSMarket){
				removedDuplicatedListCoop = Array.from(new Set(tempListCoop));	
				removedDuplicatedListCoop.forEach((item, index) => { const tempObject = {
						id: '',
						itemName: ''
				};
				tempObject.id = index;
				tempObject.itemName = item;
				this.coopDropDownListFilter.push(tempObject);
				});
	
	
				if (removedDuplicatedListCoop.length == 1){
					this.isCoopVisible = false;
				} 
	
			}
			this.spinnerService.hide();
		}, err => this.errorMsg = <any>err);
	
	}
	
	setMarketFlagForUS(){
		if (this.sessionStorage.get('marketName') == 'US Country Office'){
			this.isUSMarket = true;
		} else {
			this.isUSMarket = false;
		}
	}
	
	checkCustomRegionFieldOffice(){
		if (this.isRegionFieldOfficeVisible){
			if (this.isUSMarket){
				this.customRegionFieldOffice = "Field Office";
			} else {
				this.customRegionFieldOffice = "Region"
			}
		} else {
			this.customRegionFieldOffice = '';
		}		
	}
	
	checkCustomFieldCoop(){
		if(this.isCoopVisible){
			this.customFieldCoop = "Coop";
		} else {
			this.customFieldCoop = '';
		}
	}
	
	/**
	 * Function used to refresh the view by triggering the pipe transform function.
	 */
	triggerPipe() {
		this.num = Math.random();
	}
	
	/**
	 * Function to sort the table data.
	 * 
	 * @param key -
	 *            Column key.
	 */
	sort(key) {
		this.sortColumn = key;
		this.restSelectionModel.restaurantListForMPU.sort((a, b) => {
			if (a[key] === b[key]) {
				return 0;
			}
	
			if (a[key] === '' || a[key] === null) {
				return 1;
			} else if (b[key] === '' || b[key] === null) {
				return -1;
			} else if (a[key] > b[key]) {
				return 1;
			} else if (a[key] < b[key]) {
				return -1;
			}
			return 0;
		});
		if (this.restSelectionModel.sortAscending[key]) {
			this.restSelectionModel.restaurantListForMPU.reverse();
		}
		this.restSelectionModel.sortAscending[key] = !this.restSelectionModel.sortAscending[key];
		this.triggerPipe();
	}
	
	filterRestaurant(){
		if (this.restSelectionModel.restaurantListForMPU.length === this.duplicateRestList.length) {
			this.duplicateRestList = _.cloneDeep(this.restSelectionModel.restaurantListForMPU);
		}
	
		this.restSelectionModel.restaurantListForMPU = this.restSelectionFilterPipe.transform(
			this.duplicateRestList, this.restSelectionFilterModel, 
			this.coopStatusFilter, this.regionFieldOfficeStatusFilter);

		
		if (this.isUSMarket && this.coopStatusFilter.length == 0){
			let tempList: any = [];
			let tempListCoop: any = [];
			let removedDuplicatedListCoop: any;
			for (let tempListCoop of this.restSelectionModel.restaurantListForMPU){
				tempList.push(tempListCoop.coop) 
			}
		

			this.coopDropDownListFilter = [];
			removedDuplicatedListCoop = Array.from(new Set(tempList));	
			removedDuplicatedListCoop.forEach((item, index) => { const tempObject = {
					id: '',
					itemName: ''
			};
			tempObject.id = index;
			tempObject.itemName = item;
			this.coopDropDownListFilter.push(tempObject);
			});		
	
		}
		
	}
	
	fillNodeSelected() {
		this.nodesSelected = [];
		for (let res of this.packageScheduleService.selectedRestaurants) {
			let node = {
					nodeName: res.name,
					nodeNumber: res.child
			}
			this.nodesSelected.push(node);
			this.restSelectionModel.selectedQty = this.nodesSelected.length
		}
	}
	
	/*
	 * Function to check / uncheck all checkboxes, triggered on 'select all' click.
	 * @param : checked - true/ false.
	 */
	
	selectAllRest(selected: boolean) {
		const length = this.restSelectionModel.restaurantListForMPU.length;
		if(selected == true){
			console.log("selectAllMenus");
			this.existAnyCheckedVar = true;
			this.restSelectionModel.selectedQty = length;
		}else{
			console.log("deselectAllMenus");
			this.restSelectionModel.selectedQty = 0;
			this.existAnyCheckedVar = false;
			this.restSelectionModel.selectedRestaurants = [];
		}       
		for (let i = 0; i < length; i++) {
			this.restSelectionModel.restaurantListForMPU[i].selected = selected;      
		}    
		this.restSelectionModel.formDirty = true;    
	} 
	
	toggleCheckBox(rest, index){
		rest.selected = !rest.selected;
		(<HTMLInputElement>document.getElementById('restSelectionCheckbox'+index)).checked = rest.selected;
		this.verifyAllCheckbox();
		this.toggleRestHeader(rest.selected);
	}

	checkboxClickStopper(event){
		event.preventDefault();
		event.stopPropagation();
	}
	
	toggleRestHeader(checked) {
		if (!checked) {
			this.selectAllRestHeader = false;   
			this.restSelectionModel.selectedQty--;
		} else {
			this.selectAllRestHeader = this.restSelectionModel.restaurantListForMPU
			.every(menu => menu.selected);
			this.restSelectionModel.selectedQty++;
		}    
		this.restSelectionModel.formDirty = true; 
		this.verifyAllCheckbox();
	}
	
	verifyAllCheckbox(){
		const length = this.restSelectionModel.restaurantListForMPU.length;
		for (let i = 0; i < length; i++) {
			if(this.restSelectionModel.restaurantListForMPU[i].selected == true){
				this.existAnyCheckedVar = true;
				break;
			}else{
				this.existAnyCheckedVar = false;
	
			}
		}		
	}
	
	resetTable() {
		this.restSelectionModel.selectedQty = 0;		
		this.existAnyCheckedVar = false;
		this.selectAllRestHeader = false;
		this.selectAllRest(false);    
		this.restSelectionModel.formDirty = false; 
		this.restSelectionModel.selectedRestaurants = [];
	}
	
	onClickCancel(){
		this.headerComponent.goToRFM();
	}
	
	cancel(cancel){
        console.log("cancel");
		if (cancel == 'cancel'){
			this.modelIdCancel = "";
		}else{
			this.resetTable();
			this.modelIdCancel = "";
		}
	}
	
	loadSelectedRestList(e){
		const selectedRest = filter(this.restSelectionModel.restaurantListForMPU, { 'selected': true });
		this.restSelectionModel.selectedRestaurants = selectedRest;

		this.removeUnselectedRests();
		this.fetchRecentlyAddedRests();

		this.massPromotionUpdateModel.online.updateAndReview.showUpdateTable = false;

		$('#collapseOne').collapse('hide');
		$('#collapseTwo').collapse('show');
		$('#collapseThree').collapse('hide');

		this.promoSelectionComponent.verifyAllCheckbox();
		e.stopPropagation();
	}

	removeUnselectedRests() {
		let existFlag;
		let count = 0;
		let toBeDeleted = [];
		for(let promo of this.onlinePromoSelectionModel.promoList){
			existFlag = false;

			for(let res of this.restSelectionModel.selectedRestaurants){
				if(promo.restNumber == res.rest_nu){
					existFlag = true;
				}
			}

			if(!existFlag){
				//remove of promoList
				toBeDeleted.push(count); 
			}
			count++;
		}

		toBeDeleted.sort(function(a,b){ return b - a; });
		for(let i of toBeDeleted){
			this.onlinePromoSelectionModel.promoList.splice(i,1);
		}
	}

	fetchRecentlyAddedRests() {
		let notExistFlag;
		let toBeAdded = [];
		for(let newRest of this.restSelectionModel.selectedRestaurants){
			notExistFlag = true;

			if(this.onlinePromoSelectionModel.promoList.length){
				for(let promo of this.onlinePromoSelectionModel.promoList){
					if(promo.restNumber == newRest.rest_nu){
						notExistFlag = false;
						break;
					}
				}
			} else {
				notExistFlag = true;
			}
			
			if(notExistFlag){
				//fetch promoList from database (only the newly selected restaurants)
				toBeAdded.push(newRest); 
			}
		}

		//call java backend to fetch promotions to the newly selected restaurants
		if(toBeAdded.length){
			this.onlinePromoSelectionModel.showTable = false;
			let restList = _.cloneDeep(this.restSelectionModel.selectedRestaurants);
			this.restSelectionModel.selectedRestaurants = toBeAdded;
			this.promoSelectionComponent.searchPromoListNewlyAdded();
			this.restSelectionModel.selectedRestaurants = restList;
		}
	}
	
}
