import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


// declare var _satellite : any;;

// function bootstarpOOPApp() {
//   platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));

//  // may fail if adobe dtm script was not loaded
//   _satellite.pageBottom();
// }

// const adobeDTMScript = document.createElement('script')

// // No matter what happens with adobeDTMScript, always bootstrap OOP App.
// adobeDTMScript.onload = bootstarpOOPApp;
// adobeDTMScript.onerror = bootstarpOOPApp;
// adobeDTMScript.onabort = bootstarpOOPApp;

// if (environment.production) {
//   enableProdMode();
//   adobeDTMScript.src = '//assets.adobedtm.com/b1e0f2f1c887c13d5a8d850fc709d9f680d2011c/satelliteLib-1b041ab5ddde6c025b391d3bfed9830b580074ab.js';
// } else {
//   adobeDTMScript.src = '//assets.adobedtm.com/b1e0f2f1c887c13d5a8d850fc709d9f680d2011c/satelliteLib-1b041ab5ddde6c025b391d3bfed9830b580074ab-staging.js';
// }

// document.head.appendChild(adobeDTMScript);
