export const OOP_URL_CONST = {
    /** Url of pages */
    GENERATE_REPORT_PAGE: '/generateReport',
    GENERATE_REPORT_LIST_PAGE: '/generateReport/list',
    GENERATE_REPORT_GMA_PAGE:'/generateReport/GMA',
    GENERATE_REPORT_VALIDATION_REPORT_PAGE: '/generateReport/validationView',
    GENERATED_PACKAGES_PAGE: "/packagegeneration/generatedpackages",
    PACKAGE_GENERATION_PAGE: "/packagegeneration",
    PACKAGE_GENERATION_CONFIRMATION_PAGE: "/packagegeneration/confirmation",
    PACKAGE_GENERATION_STATUS_REPORT_PAGE: "/packagegeneration/statusreport",
    OWNER_OPERATOR_HOMEPAGE: '/owner-operator-homepage', 
    PRICE_TAX_ACTIVATE_PAGE: '/pta',
    PRICE_TAX_ACTIVATE_EFFECTIVEDATE_PAGE: '/pta/effective-date',
    PRICE_TAX_ACTIVATE_CONFIRM_PRICE_PAGE: '/pta/confirm-price',
    PRICE_TAX_ACTIVATE_SELECT_RESTAURANT_PAGE:'/pta/select-restaurants',
    PRICE_TAX_ACTIVATE_SELECT_RESTAURANT_TREE_PAGE: '/pta/select-restaurant-tree',
    PRICE_TAX_ACTIVATE_MENU_ITEM_PAGE: '/pta/menu-item',
    PRICE_TAX_ACTIVATE_PRICE_PAGE: '/pta/price',
    PRICE_TAX_ACTIVATE_TAX_PAGE: '/pta/tax',
    MESSAGE_MANAGEMENT_MESSAGES_PAGE:'/message-management/messages',      
    MESSAGE_MANAGEMENT_CREATE_MESSAGE_PAGE:'/message-management/create-message',
    MESSAGE_MANAGEMENT_EDIT_MESSAGE_PAGE: '/message-management/create-message?isEdit=true',
    MESSAGE_MANAGEMENT_PAGE:'/message-management',           
    MESSAGE_MANAGEMENT_VIEW_MESSAGE_PAGE:'/message-management/view-message',
    ACTION_ITEM_PAGE:'/action-item',     
    ACTION_ITEM_CREATE_PAGE:'/action-item/create', 
    ACTION_ITEM_EDIT_PAGE:'/action-item/edit',  
    ACTION_ITEM_EDIT_ROUTE: '/action-item/edit?isEdit=true',  
    ACTION_ITEM_VIEW_PAGE:'/action-item/view',  
    ACTION_ITEM_CONFIRMATION_PAGE:'/action-item/confirmation',
    ACTION_ITEM_OWNER_OPERATOR_PAGE: "/action-item/owner-operator",      
    ACTION_ITEM_OWNER_OPERATOR_VIEW_PTA_VIEW_PAGE: "/owneroperatorview/ptaView",
    ACTION_ITEM_OWNER_OPERATOR_VIEW_MII_VIEW_PAGE: "/owneroperatorview/miiView",
    MASS_MENU_ITEM_UPDATE_PAGE: '/mass-menu-item-update',
    POS_PAGE:'/pos',
    DIMENSIONGROUP_PAGE:'/dimension-group',
    DIMENSIONGROUP_CREATE_PAGE:'/dimension-group/create',
    DIMENSIONGROUP_EDIT_PAGE:'/dimension-group/edit',
    POS_SECTION:'/pos/section',
    POS_CONFIRM:'/pos/confirm',
    MASS_MENU_ITEM_INACTIVATION_PAGE:'/menu-item-inactivation',
    MASS_MENU_ITEM_INACTIVATION_EFFECTIVE_DATE:'/menu-item-inactivation/effective-date',  
    MASS_MENU_ITEM_INACTIVATION_SELECT_RESTAURANT_PAGE:'/menu-item-inactivation/select-restaurants',
    MASS_MENU_ITEM_INACTIVATION_SELECT_RESTAURANT_TREE_PAGE:'/menu-item-inactivation/select-restaurant-tree',
    MASS_MENU_ITEM_INACTIVATION_MENU_ITEM_PAGE:'/menu-item-inactivation/menu-item',         
    MASS_MENU_ITEM_INACTIVATION_INACTIVATION_PAGE:'/menu-item-inactivation/inactivation',      
    MASS_MENU_ITEM_INACTIVATION_CONFIRMATION_PAGE:'/menu-item-inactivation/confirmation',
    REPORT_LIST_PAGE: '/reports/list',
    APPLICATION_ROUTING_URLS:'./assets/mock-json/applicationRoutingUrl.json',
    LOGIN_URL: '/rfm2OnlineApp/checkSession_refreshSession.action',
    RFM_LOGIN_ACTION_URL: '/rfm2OnlineApp/rfmLogin.action',
    RFM_LOGOUT_ACTION_URL: '/rfm2OnlineApp/logout.action',
    RFM_CHECKSESSION_URL: '/rfm2OnlineApp/checkSession_checkSession.action',
    MSG_ADMIN_ROUTE: '/message-management',
    MSG_USER_ROUTE: '/message-management/messages',
    MSG_CREATE_ROUTE: '/message-management/create-message',
    MSG_VIEW_ROUTE: '/message-management/view-message',
    MSG_EDIT_ROUTE: '/message-management/create-message?isEdit=true',
    OOP_HP_ROUTE: '/owner-operator-homepage',
    SELF_ROUTE: '/',
}