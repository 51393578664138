import { Component, OnInit, QueryList, ViewChildren, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { MassPromotionUpdateModel } from '../model/masspromotionupdate.model';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { filter, map, pick, partialRight, cloneDeep } from 'lodash';
import { MassPromoUpdatePipe } from '../utilities/mass-promo-update.pipe';
import { appconstant } from '../../../../environments/app.constant';
import { MassPromotionUpdateHttpService } from '../services/masspromotionupdate-http.service';
import { MassPromotionUpdateComponent } from '../masspromotionupdate.component';
import { DropdownService } from '../../../services/dropdown.service';
import * as moment from 'moment';
import 'moment-timezone';

import { CommonService } from '../../../services/common.service';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Inject, Injectable } from '@angular/core';
import { HeaderComponent } from '../../header/header.component';
import { User } from '../../../models/user';

declare var $: any;
@Component({
  selector: 'app-masspromotionupdate-update-and-review',
  templateUrl: './masspromotionupdate.updateandreview.component.html',
  styleUrls: ['./masspromotionupdate.updateandreview.component.css'],
  providers: [MassPromoUpdatePipe]
})
export class MassPromotionUpdateAndReviewComponent implements OnInit {
  @ViewChildren('promoStartDate') promoStartDates: QueryList<ElementRef>;
  @ViewChildren('promoEndDate') promoEndDates: QueryList<ElementRef>;

  sortColumn = '';
  num = 0;
  dataCopy = [];
  modelIdCancel = '';
  constructor(
    public massPromotionUpdateModel: MassPromotionUpdateModel,
    public massPromotionUpdateComponent: MassPromotionUpdateComponent,
    private dropdownService: DropdownService,
    private spinnerService: Ng4LoadingSpinnerService,
    private promoFilterPipe: MassPromoUpdatePipe,
    private massPromotionUpdateHttpService: MassPromotionUpdateHttpService,
    public commonService : CommonService,
    private headerComponent : HeaderComponent,
    @Inject(SESSION_STORAGE) public sessionStorage: StorageService
  ) { }

  promoUpdateModel = this.massPromotionUpdateModel.online.updateAndReview;
  promoUpdateFilterModel = this.massPromotionUpdateModel.online.updateAndReview.filter;
  promoUpdateSortModel = this.massPromotionUpdateModel.online.updateAndReview.sortAscending;
  resultsModel = this.massPromotionUpdateModel.online.results;
  resultsFilterModel = this.massPromotionUpdateModel.online.results.filter;
  promoSelectionModel = this.massPromotionUpdateModel.online.promoSelection;
  
  constants = appconstant;

  statusList = [];
  errorMsg: any;
  permissions: any;
  userDetails: User;

  statusPromoListSettings = {};
  promoSelectedItems = [];
  promoDropDownListStatus = [];

  //STATUS FILTER - TABLE RESULT LIST
	promoStatusFilter = [];
  statusResultTableSettings = {};
  
  promoDateError: boolean;
  promoDateMsg :any ='';

  dateFormateSelector: any;
  defaultCalenderDateLocal: any;
  momentsDateFormat = 'MMM D, YYYY';
  momentsBackendDateFormat = 'MM/DD/YYYY';
  timeZone = '';

  msg = '0 records found for the criteria.';
  

  promoDataChangedListStrtDate = new Map();
  promoDataChangedListEndDate = new Map();
  promoDataChangedListStatus = new Map();

 	ngOnInit() {
      this.promoUpdateModel.disableUpdateNoChanges = true;
      this.promoUpdateModel.disableUpdateDateError = true;

      this.massPromotionUpdateModel.online.updateAndReview.showUpdateTable = true;
      this.sessionStorage.set('promoDataChangedFlag',"false");
      
      this.commonResultAction();

      //filter settings
      this.statusResultTableSettings = this.dropdownService.dropDownResultTableSettings;

      this.promoUpdateModel.promoDropDownListFilterStatus =  this.massPromotionUpdateHttpService.getFltrSts(
        this.massPromotionUpdateHttpService.getUniqueData(
          this.promoUpdateModel.table, 'promoStatus'), 'promoStatus');
      
      this.loadPromoDropdowns();

      // getting time zone from local storage
      this.timeZone = this.sessionStorage.get('timeZone');

      moment.tz.setDefault(this.timeZone);
      moment.locale(this.timeZone);
      // setting today's date to default
      this.defaultCalenderDateLocal = moment().tz(this.timeZone).format('MM/DD/YYYY');
      this.dateFormateSelector = 'M d, yyyy';

      this.retrievePermissions();
  }

  retrievePermissions(){
    this.userDetails = this.sessionStorage.get('userDetails');
    if (this.userDetails && this.userDetails['permissionMap']) {
      this.permissions = this.userDetails['permissionMap'];
    }
  }
  
commonResultAction() {
  this.promoUpdateFilterModel = {
    restNumber : '',
    nodeName : '',
    promoCode : '',
    promoName : '',
    promoLongDesc : '',
    promoTemplateLabel : '',
    strtDate : '',
    endDate : '',
    status : ''
  };
  this.promoUpdateSortModel = {
    restNumber: false,
    nodeName: false,
    promoCode: false,
    promoName: false,
    promoLongDesc: false,
    promoTemplateLabel: false,
    strtDate: false,
    endDate: false,
    status: false
  };
}

	/**
	* Function used to refresh the view by triggering the pipe transform function.
	*/
	triggerPipe() {
		this.num = Math.random();
	}
  
  /**
   * Function used enable/disable update button, based on any data entered in form.
   * @param event - Event object.
   */
  anyFieldUpdated(event) {     
    const data = filter(this.promoUpdateModel.table,
      function (o) {
        if (o.strtDate || o.endDate || o.status){
          return true;
        } else {
          return false;
        }
      });
  }
  
  loadPromoDropdowns() {
    this.massPromotionUpdateHttpService.loadPromoLookup("loadPromoLookup", this.massPromotionUpdateModel.userBean)
			.subscribe((lookupList) => {
        this.promoSelectedItems = [];
        this.promoDropDownListStatus = [];
        this.statusList = [];
        this.statusList = this.massPromotionUpdateHttpService
            .getStatusDropdownDataRemoveAll(lookupList.statusList);
		}, err => this.errorMsg = <any>err);
 }

  /**
   * Function to reset the accordion changes.
   */
  resetUpdates() {
    this.promoUpdateModel.table = [];
    $('#collapseThree').collapse('toggle');
    $('#collapseTwo').collapse('toggle');
    this.promoUpdateModel.disableUpdateNoChanges = true;
    this.promoUpdateModel.disableUpdateDateError = true;
    this.promoSelectionModel.selectAllPromoHeader = false;
  }

  /**
   * Function to sort the table data.
   * @param key - Column key.
   */
  sort(key) {
    this.sortColumn = key;
    this.promoUpdateModel.table.sort((a, b) => {
      if (a[key] === b[key]) {
        return 0;
      }

      if (a[key] === '' || a[key] === null) {
        return 1;
      } else if (b[key] === '' || b[key] === null) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else if (a[key] < b[key]) {
        return -1;
      }
      return 0;
    });
    if (this.promoUpdateSortModel[key]) {
      this.promoUpdateModel.table.reverse();
    }
    this.promoUpdateSortModel[key] = !this.promoUpdateSortModel[key];
    //this.triggerPipe();
  }

  filterPromo(){
		if (this.promoUpdateModel.table.length === this.promoUpdateModel.duplicatePromoUpdateList.length) {
		    this.promoUpdateModel.duplicatePromoUpdateList = _.cloneDeep(this.promoUpdateModel.table);
    }
    
		this.promoUpdateModel.table = this.promoFilterPipe.transform(
        this.promoUpdateModel.duplicatePromoUpdateList, this.promoUpdateFilterModel, this.promoStatusFilter);

    $.getScript('assets/js/bootstrap-datepicker.js');
    $.getScript('assets/js/datepicker.js');
  }
  
  verifyPromoChangedFields(){
    if (this.sessionStorage.get('promoDataChangedFlag') == "true"){
        this.promoUpdateModel.disableUpdateNoChanges = false;
		} else {
				this.promoUpdateModel.disableUpdateNoChanges = true;
    }

    const length = this.promoUpdateModel.table.length;
		for (let i = 0; i < length; i++) {
      if(this.promoUpdateModel.table[i].promoStrtDateError 
          || this.promoUpdateModel.table[i].promoEndDateError){
        this.promoUpdateModel.disableUpdateDateError = true;
				break;
			}else{
				this.promoUpdateModel.disableUpdateDateError = false;
			}
		}
  }
  
  calendarClick(calendarField, index){
    setTimeout(() => {
      document.getElementById(calendarField+index).click();
    });
  }

  promoDateValidate(data, promoEffectiveDate, index, objectViewDate, objectBackendDate, dateField, dateError, dateMessage, verifyBetweenDatesField){
		setTimeout(() => {
      let promoHashMap = data.promoCode + "_" + data.restNumber;

			if(promoEffectiveDate){
				const checkFormat = (moment(promoEffectiveDate, this.momentsDateFormat, true)).isValid();
				if(checkFormat){
          let promoEffectiveDateTemp = document.getElementById(dateField+index)['value'];
          
          if (objectViewDate == 'strtDate'){
            if (this.promoUpdateModel.duplicatedSelectedPromoList[index].strtDate != promoEffectiveDateTemp){
        		  if (this.promoDataChangedListStrtDate.size == 0) {
        			  this.promoDataChangedListStrtDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].strtDate)  
        		  } else {
        			  if (!this.promoDataChangedListStrtDate.has(promoHashMap)) {
        				  this.promoDataChangedListStrtDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].strtDate)
        			  }
        		  }        	  
        	  } else {
        		  if (this.promoDataChangedListStrtDate.size > 0) {
        			  if (this.promoDataChangedListStrtDate.has(promoHashMap)) {
        				  this.promoDataChangedListStrtDate.delete(promoHashMap)
        			  }
        		  }
        	  }
          } else {
        	  if (this.promoUpdateModel.duplicatedSelectedPromoList[index].endDate != promoEffectiveDateTemp){
        		  if (this.promoDataChangedListEndDate.size == 0) {
        			  this.promoDataChangedListEndDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].endDate)
        		  } else {
        			  if (!this.promoDataChangedListEndDate.has(promoHashMap)) {
        				  this.promoDataChangedListEndDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].endDate)
        			  }
        		  }        	  
        	  } else {
        		  if (this.promoDataChangedListEndDate.size > 0) {
        			  if (this.promoDataChangedListEndDate.has(promoHashMap)) {
        				  this.promoDataChangedListEndDate.delete(promoHashMap)
        			  }
        		  }
        	  }
          }
          
          if (this.promoDataChangedListStrtDate.size == 0 
              && this.promoDataChangedListEndDate.size == 0 
              && this.promoDataChangedListStatus.size == 0){
        	  this.sessionStorage.set('promoDataChangedFlag',"false");
          } else {
        	  this.sessionStorage.set('promoDataChangedFlag',"true");
          }

					const isValid = (moment(promoEffectiveDateTemp, this.momentsDateFormat, true)).isValid();
					if (isValid) {
            let backendDate = moment(promoEffectiveDateTemp, this.momentsDateFormat).format(this.momentsBackendDateFormat);

            let dateFromToValidate;
            if(verifyBetweenDatesField == 'promotionDateTo'){
              dateFromToValidate = moment(data[verifyBetweenDatesField]).isSameOrAfter(backendDate);
            } else {
              dateFromToValidate = moment(data[verifyBetweenDatesField]).isSameOrBefore(backendDate);
            }
            if(dateFromToValidate){
						  data[dateError] = false;
              data[objectViewDate] = promoEffectiveDateTemp;
              data[objectBackendDate] = backendDate;
            } else {
              data[objectViewDate] = '';
              data[dateError] = true;
              document.getElementById(dateField+index)['value'] = '';
              if(verifyBetweenDatesField == 'promotionDateTo'){
                data[dateMessage] = 'Please, select a lower value.';
              } else {
                data[dateMessage] = 'Please, select a higher value.';
              }
            }
					}else{  
						data[objectViewDate] = '';                
						data[dateError] = true;
						data[dateMessage] = 'Invalid input';
					}
				}else{
					data[objectViewDate] = '';         
					data[dateError] = true;
					data[dateMessage] = 'Invalid input';
				}
			}else{
        let promoEffectiveDateTemp = document.getElementById(dateField+index)['value'];

        if (objectViewDate == 'strtDate'){
          if (this.promoUpdateModel.duplicatedSelectedPromoList[index].strtDate != promoEffectiveDateTemp){
            if (this.promoDataChangedListStrtDate.size == 0) {
              this.promoDataChangedListStrtDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].strtDate)  
            } else {
              if (!this.promoDataChangedListStrtDate.has(promoHashMap)) {
                this.promoDataChangedListStrtDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].strtDate)
              }
            }        	  
          } else {
            if (this.promoDataChangedListStrtDate.size > 0) {
              if (this.promoDataChangedListStrtDate.has(promoHashMap)) {
                this.promoDataChangedListStrtDate.delete(promoHashMap)
              }
            }
          }
        } else {
          if (this.promoUpdateModel.duplicatedSelectedPromoList[index].endDate != promoEffectiveDateTemp){
            if (this.promoDataChangedListEndDate.size == 0) {
              this.promoDataChangedListEndDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].endDate)
            } else {
              if (!this.promoDataChangedListEndDate.has(promoHashMap)) {
                this.promoDataChangedListEndDate.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].endDate)
              }
            }        	  
          } else {
            if (this.promoDataChangedListEndDate.size > 0) {
              if (this.promoDataChangedListEndDate.has(promoHashMap)) {
                this.promoDataChangedListEndDate.delete(promoHashMap)
              }
            }
          }
        }
        
        if (this.promoDataChangedListStrtDate.size == 0 
            && this.promoDataChangedListEndDate.size == 0 
            && this.promoDataChangedListStatus.size == 0){
          this.sessionStorage.set('promoDataChangedFlag',"false");
        } else {
          this.sessionStorage.set('promoDataChangedFlag',"true");
        }

				const isValid = (moment(promoEffectiveDateTemp, this.momentsDateFormat, true)).isValid();
				if (isValid) {
          let backendDate = moment(promoEffectiveDateTemp, this.momentsDateFormat).format(this.momentsBackendDateFormat);

          let dateFromToValidate;
          if(verifyBetweenDatesField == 'promotionDateTo'){
            dateFromToValidate = moment(data[verifyBetweenDatesField]).isSameOrAfter(backendDate);
          } else {
            dateFromToValidate = moment(data[verifyBetweenDatesField]).isSameOrBefore(backendDate);
          }

					if(dateFromToValidate){
            data[dateError] = false;
            data[objectViewDate] = promoEffectiveDateTemp;
            data[objectBackendDate] = backendDate;
          } else {
            data[objectViewDate] = '';
            data[dateError] = true;
            document.getElementById(dateField+index)['value'] = '';
            if(verifyBetweenDatesField == 'promotionDateTo'){
              data[dateMessage] = 'Please, select a lower value.';
            } else {
              data[dateMessage] = 'Please, select a higher value.';
            }
          }
				}else{
					data[objectViewDate] = '';         
					data[dateError] = true;
					data[dateMessage] = 'Invalid input';
				}
      }
      this.verifyPromoChangedFields();
    }, 200);
  }
  
  /**
   * Function to update the database values with the user entered values.
   */
  updateRecords() {
    this.spinnerService.show();
    this.massPromotionUpdateHttpService.massPromoUpdate('massPromotionUpdate', this.massPromotionUpdateModel.online.updateAndReview)
      .subscribe((resultsData) => {
        this.resultsModel.table = resultsData.promotionUpdateList;
        this.resultsModel.duplicatePromoResultList = _.cloneDeep(resultsData.promotionUpdateList);

        this.resultsModel.promoDropDownListFilterStatus =  this.massPromotionUpdateHttpService.getFltrSts(
					this.massPromotionUpdateHttpService.getUniqueData(
					  this.resultsModel.table, 'promoStatus'), 'promoStatus');

        this.spinnerService.hide();
        
        this.massPromotionUpdateModel.online.isUpdate = false;
        this.massPromotionUpdateModel.online.updateAndReview.showUpdateTable = false;
        
        this.massPromotionUpdateComponent.showPossibleNextStepsMessage = true;
        this.massPromotionUpdateComponent.possibleNextStepsMessage = "Possible Next Steps: ";

        if(resultsData.promotionUpdateList && resultsData.promotionUpdateList.length > 1){
          this.massPromotionUpdateModel.online.results.updatedQty = resultsData.promotionUpdateList.length
            + " Promotions have been updated"
        } else {
          this.massPromotionUpdateModel.online.results.updatedQty = resultsData.promotionUpdateList.length
            + " Promotion has been updated"
        }

        this.sessionStorage.set('promoDataChangedFlag',"false");
        
        this.commonService.topFunction();
      });
  }

	onClickCancel(){
//		if (this.promoUpdateModel.table.length > 0){
//				this.modelIdCancel = '#modal-question-PromoUpdate';
//			}
		if (this.sessionStorage.get('promoDataChangedFlag') == "true"){
			this.modelIdCancel = '#modal-question-PromoUpdate';
		} else {
			this.headerComponent.goToRFM();
		}
	}
	
	cancel(cancel){
		if (cancel == 'cancel'){
			this.modelIdCancel = "";
		}else{
			this.resetUpdates();
			this.modelIdCancel = "";
			this.headerComponent.goToRFM();
		}
	}	
	
	checkStatusHasChanged(data, index){
    let promoHashMap = data.promoCode + "_" + data.restNumber;

		if (this.promoUpdateModel.duplicatedSelectedPromoList[index].promoStatus != data.promoStatus){
			if (this.promoDataChangedListStatus.size == 0) {
				this.promoDataChangedListStatus.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].promoStatus)  
			} else {
				if (!this.promoDataChangedListStatus.has(promoHashMap)) {
					this.promoDataChangedListStatus.set(promoHashMap, this.promoUpdateModel.duplicatedSelectedPromoList[index].promoStatus)
				}
			}        	  
		} else {
			if (this.promoDataChangedListStatus.size > 0) {
				if (this.promoDataChangedListStatus.has(promoHashMap)) {
					this.promoDataChangedListStatus.delete(promoHashMap)
				}
			}
		}
    if (this.promoDataChangedListStrtDate.size == 0 
        && this.promoDataChangedListEndDate.size == 0 
        && this.promoDataChangedListStatus.size == 0){
			this.sessionStorage.set('promoDataChangedFlag',"false");
		} else {
			this.sessionStorage.set('promoDataChangedFlag',"true");
    }

    this.verifyPromoChangedFields();
	}
	
}
