
import {merge as observableMerge, throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map, distinct} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Http, Response, HttpModule, Headers, RequestOptions } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';

  
import { debug } from 'util';
import { HttpCoreService } from '../../../services/http-core.service'; 
import { environment } from '../../../../environments/environment';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class PackageScheduleService {

	selectedRestaurants = [];

	constructor(public localStorageService: LocalStorageService, public httpCoreService: HttpCoreService,
			public httpClient: HttpClient, @Inject(SESSION_STORAGE) private sessionStorage: StorageService) { } 

	/*
	 * Calling actual or mock data based on enviroment as post method for Manage
	 * Package Schedule screen. Returning the data to required function in
	 * component
	 */  

	public getPackageScheduleDetails(apiService, requestPckShedule): Observable<any> {      
		return this.httpCoreService.getHttpUrl(apiService, requestPckShedule, 'post');
	}

	public deletePackageScheduleDetails(apiService, requestPckShedule): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService, requestPckShedule, 'post');
	}

	public getRestaurantData(apiService, returnStatus: number) {
		const options = {};
		/*
		 * return this.httpClient.get('assets/mock-json/treeMockShort.json')
		 * .map((response: Response) => { return response; });
		 */
		return this.httpCoreService.getHttpUrl(apiService, this.restaurantRequestData(returnStatus), 'post').pipe(
		map((response: Response) => {
			const tempArray = [];
			tempArray.push(response);
			return tempArray;
		}),
		catchError((e: any) => observableThrowError(this.errorHandler(e))),);
	}

	public restaurantRequestData(returnStatus: number) {
		const userDetails = this.getValidationDetail();
		const marketIdList = [];
		marketIdList.push(userDetails.mkt);
		const treeRequest = {
				'marketIdList': marketIdList,
				'userId': userDetails.eid,
				'localeLangID': '1',
				'moduleName': marketIdList[0] == 0 ? '' : 'USER',
						'searchString': null,
						'searchOption': null,
						'selectByID': null,
						'returnStatus':returnStatus,
						'isSearchRequired': false,
						'id': userDetails.id
		};
		return treeRequest;
	}

	getValidationDetail() {
		let eid = '';
		let marketId = '';
		let id = '';
		if (this.localStorageService.get('userEid')) {
			eid = this.localStorageService.get('userEid');
		} else {
			eid = this.sessionStorage.get('userEid');
		}
		if (this.localStorageService.get('marketId')) {
			marketId = this.localStorageService.get('marketId');
		} else {
			marketId = this.sessionStorage.get('marketId');
		}
		if (this.sessionStorage.get('userId')) {
			id = this.sessionStorage.get('userId');
		} else {
			id = this.localStorageService.get('userDetails')['id'];
		}
		return { eid: eid, mkt: marketId, id: id };
	}

	treeSearchRequest(reqObj, currentTree) {
		const userDetails = this.getValidationDetail();
		const marketIdList = [];
		marketIdList.push(userDetails.mkt);
		const req = {
				'marketIdList': marketIdList,
				'userId': userDetails.eid,
				'localeLangID': '1',
				'moduleName': currentTree === 'restaurant' ? 'USER' : 'AL',
						'searchString': '',
						'searchOption': 'null',
						'selectByID': 'null',
						'isSearchRequired': 'false',
						searchCriteria: reqObj
		};
		if (marketIdList[0] == 0 && currentTree === 'restaurant') {
			req.moduleName = '';
		}
		return req;
	}

	public treeSearch(apiService, reqData, currentTree): Observable<any> {
		return this.httpCoreService.getHttpUrl(apiService,
				this.treeSearchRequest(reqData, currentTree), 'post').pipe(
				map((response: Response) => {
					const tempArray = [];
					tempArray.push(response);
					return tempArray;
				}
				),catchError((e: any) => observableThrowError(this.errorHandler(e))),);
	}
	/* Getting the schedule window period */
	public getPackageScheduleWindowPeriod(apiService, PackageScheduleWindowPeriodRequest): Observable<any>{
		return this.httpCoreService.getHttpUrl(apiService, PackageScheduleWindowPeriodRequest, 'post');
	}

	/* save packageschedule */
	public savePackageSchedule(apiService, PackageScheduleRequest): Observable<any>{
		return this.httpCoreService.getHttpUrl(apiService, PackageScheduleRequest, 'post'); 
	}

	/* fiter dropdown for package/list screen starts */
	public getUniqueData(data, arg){
		let filteredData = [];

		observableMerge(data).pipe(
		distinct((x) => x[arg]))
		.subscribe(y => {
			filteredData.push(y);
		});
		return filteredData;
	}

	public getMarcketUniqueData(data){
		let filteredData = [];
		observableMerge(data).pipe(
		distinct())
		.subscribe(y => {
			filteredData.push(y);
		});
		return filteredData;
	}


	public getFltrSts(data, arg, func){

		// let filteredData = [];
		const tempArray = [];

		// filteredData = Array.from(new Set(data.map((itemInArray) =>
		// itemInArray[arg])));

		if (data && data.length) {
			if(func == "export"){   
				const tempObject = {
						id : '0',
						itemName : 'All'
				};
				tempArray.push(tempObject);
				data.forEach(item => {
					const tempObject = {
							id : '',
							itemName : ''
					};
					tempObject.id = item[arg];
					tempObject.itemName = this.getFormatDataForExportStatus(item[arg]) ;
					tempArray.push(tempObject);
				});
			} else{
				if(func == "pkgStatus"){
					const tempObject = {
							id : '0',
							itemName : 'All'
					};
					tempArray.push(tempObject);
					data.forEach(item => {
						const tempObject = {
								id : '',
								itemName : ''
						};
						tempObject.id = item[arg];
						tempObject.itemName = this.getFormatDataForPkgStatus(item[arg]) ;
						tempArray.push(tempObject);
					});
				}else{
					data.forEach(item => {
						const tempObject = {
								id : '',
								itemName : ''
						};

						tempObject.id = item;          
						tempObject.itemName = item;
						tempArray.push(tempObject);
						// tempObject.itemName = item[arg];

					});       
				}
			}
		}
		return tempArray;
	}

	getFormatDataForPkgStatus(val){
		if(val == 'prequested'){     
			return 'Requested';
		}else if(val == 'psuspend'){
			return 'Package Generation Suspended';
		}else if(val == 'pinprocess'){
			return 'In-Process';
		}else if(val == 'pgenfailed'){
			return 'Failed';
		}else if(val == 'pgenwarning'){
			return 'Warnings';
		}else if(val == 'punfinished'){
			return 'Unfinished';
		}else if(val == 'pgenerated'){
			return 'Generated';
		}
	}

	getFormatDataForExportStatus(val){
		if (val == 'Package.Export.Yes') {
			return 'Y';
		}
		if (val == 'Package.Export.No') {
			return 'N';
		}
	}
	/* fiter dropdown for package/list screen ends */

	errorHandler(error: any): void {
	}
}
