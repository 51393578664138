import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject } from 'rxjs';
import { appconstant } from '../../environments/app.constant';

@Injectable()
export class CommonService {

  constants = appconstant;
  device: string = this.constants.DESKTOP;
  defDevType = new BehaviorSubject(this.constants.DESKTOP);
  deviceType = this.defDevType.asObservable();

  constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService,
		  private localStorageService:LocalStorageService) { }

  // When the user clicks on the button, scroll to the top of the document
 public topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
 }
 // When the user clicks on the button, scroll to the bottom of the document
 public bottomFunction() {
  document.body.scrollTop = document.body.clientHeight;
  document.documentElement.scrollTop = document.body.clientHeight;
 }

 // When the user scrolls down 20px from the top of the document, show the buttons accordingly
 public scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
     return true;
    } else {
     return false;
    }
  }

  // When there is no scroll, donot show the button
 public showScrollButton() {
  return(document.documentElement.scrollHeight > document.documentElement.clientHeight) ;
}
  public resetFunctionIdForHelp(val) {
    this.localStorageService.set(val, false);
    this.sessionStorage.set(val, false);
  }

  public detectScreen(width) {
    if(width >= this.constants.MINWIDTH && width <= this.constants.MAXWIDTH){
      this.device = this.constants.TABLET;
    }else if(width > this.constants.MAXWIDTH){
      this.device = this.constants.DESKTOP;
    }else{
      this.device = this.constants.MOBILE;
    }
    this.sessionStorage.set('deviceType', this.device);
    this.defDevType.next(this.device);
  }

  public getTooltipActionType() {
    let deviceType = this.sessionStorage.get('deviceType');
    if(deviceType == this.constants.TABLET || deviceType == this.constants.MOBILE) {
      return this.constants.CLICK;
    } else {
      return this.constants.HOVER;
    }
  }

  public getCharLengthForEllipse(){
    let deviceType = this.sessionStorage.get('deviceType');
    if(deviceType == this.constants.TABLET || deviceType == this.constants.MOBILE) {
      return this.constants.MOBILECHARLIMIT;
    } else {
      return this.constants.DESKTOPCHARLIMIT;
    }
  }
}
